/** @jsxImportSource @emotion/react */
import React, {useState} from 'react';
import {styled} from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import PrimaryDrawer from './PrimaryDrawer';
import {useSelector} from '../store';
import {AuthUser} from '../store/auth';
import {signOut} from '../util/auth';
import {useHistory} from 'react-router-dom';
import config from '../config'

const MenuIconButton = styled(IconButton)(
    ({theme}) => `
    margin-right: ${theme.spacing(2)}
`
);

const Title = styled(Typography)({
    flexGrow: 1,
});

function MainTopAppBar() {
    let history = useHistory();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const openUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeUserMenu = () => {
        setAnchorEl(null);
    };

    // Drawer
    const [primaryDrawerState, setPrimaryDrawerState] = React.useState<boolean>(false);

    const authUser = useSelector<AuthUser>((state) => state.auth.auth_id!.auth_user);

    let titleColor = config.is_staging() ? 'red' : 'white'

    return (
        <div>
            <AppBar>
                <Toolbar variant={'dense'}>
                    <MenuIconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => {
                            setPrimaryDrawerState(!primaryDrawerState);
                        }}
                        size="large">
                        <MenuIcon/>
                    </MenuIconButton>
                    <Title style={{color: titleColor}} variant="h6">BluGrid {config.is_prod() ? '' : config.env}</Title>
                    <Typography variant="h6" onClick={openUserMenu} style={{cursor: 'pointer'}}>
                        {authUser.first_name}
                    </Typography>
                    <Menu
                        anchorEl={anchorEl}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                        transformOrigin={{vertical: 'top', horizontal: 'center'}}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={closeUserMenu}>
                        <MenuItem
                            onClick={() => {
                                setAnchorEl(null);
                                history.push('/');
                                signOut();
                                window.location.reload();
                            }}>
                            Sign Out
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <PrimaryDrawer primaryDrawerState={primaryDrawerState} setPrimaryDrawerState={setPrimaryDrawerState}/>
            <Toolbar variant={'dense'}/>
        </div>
    );
}

export default MainTopAppBar;
