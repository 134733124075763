import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { Add, CloudUpload } from '@mui/icons-material';
import React from 'react';

type Props = {
    anchorEl: HTMLElement | null;
    handleClose: () => void;
    handleCreateTable: () => void;
    handleUploadFile: () => void;
};

export const CreateTableMenu = ({ anchorEl, handleClose, handleCreateTable, handleUploadFile }: Props) => {
    return (
        <Menu
            id="create-table-menu"
            anchorEl={anchorEl}
            open={anchorEl !== null}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}>
            <MenuItem onClick={handleCreateTable}>
                <ListItemIcon>
                    <Add fontSize="small" />
                </ListItemIcon>
                <ListItemText>Create Table</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleUploadFile}>
                <ListItemIcon>
                    <CloudUpload fontSize="small" />
                </ListItemIcon>
                <ListItemText>Create from File</ListItemText>
            </MenuItem>
        </Menu>
    );
};
