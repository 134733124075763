/** @jsxImportSource @emotion/react */
import React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useHistory } from 'react-router-dom';
import { DevicesOther, Home } from '@mui/icons-material';
import Toolbar from '@mui/material/Toolbar';
import { ListItem } from '@mui/material';
import { styled } from '@mui/material/styles';

const MenuContainer = styled('div')({
    width: 250,
});

type PrimaryDrawerProps = {
    primaryDrawerState: boolean;
    setPrimaryDrawerState: (newVal: boolean) => void;
};

function PrimaryDrawer(props: PrimaryDrawerProps) {
    let history = useHistory();

    const SlideMenuList = () => (
        <MenuContainer role="presentation">
            <List>
                <ListItem
                    button
                    onClick={() => {
                        history.push('/');
                        props.setPrimaryDrawerState(!props.primaryDrawerState);
                    }}>
                    <ListItemIcon>
                        <Home />
                    </ListItemIcon>
                    <ListItemText primary={'Home'} />
                </ListItem>
                <ListItem
                    button
                    onClick={() => {
                        history.push('/other');
                        props.setPrimaryDrawerState(!props.primaryDrawerState);
                    }}>
                    <ListItemIcon>
                        <DevicesOther />
                    </ListItemIcon>
                    <ListItemText primary={'Other'} />
                </ListItem>
            </List>
        </MenuContainer>
    );
    return (
        <Drawer
            style={{ zIndex: 150 }}
            open={props.primaryDrawerState}
            onClose={() => {
                props.setPrimaryDrawerState(!props.primaryDrawerState);
            }}>
            <Toolbar variant={'dense'} />
            <SlideMenuList />
        </Drawer>
    );
}

export default PrimaryDrawer;
