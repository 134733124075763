import React from 'react';
import { ValidationConfig, ValidationConfigType } from './types';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

type ManageValidationConfig = [ValidationConfig, (newVal: ValidationConfig) => void];

type Props = {
    userInteractionDisabled: boolean;
    manageValidationConfig: ManageValidationConfig;
};

export const ValidationConfigFields = ({ userInteractionDisabled, manageValidationConfig }: Props) => {
    let [validationConfig, setValidationConfig] = manageValidationConfig;
    return (
        <React.Fragment>
            <FormControl variant={'standard'} sx={{ my: 2, minWidth: 180 }} disabled={userInteractionDisabled}>
                <InputLabel id="validation-type-select-label">Validation Type</InputLabel>
                <Select
                    disabled={userInteractionDisabled}
                    labelId="validation-type-select-label"
                    id="validation-type-select"
                    value={validationConfig.validation_type || ''}
                    label="Validation Type"
                    onChange={(event) => {
                        let newVal;
                        let validationPython = validationConfig.python_code;
                        if (event.target.value === '') {
                            newVal = undefined;
                            validationPython = undefined;
                        } else {
                            newVal = ValidationConfigType[event.target.value as keyof typeof ValidationConfigType];
                            if (newVal !== ValidationConfigType.PYTHON) validationPython = undefined;
                        }
                        setValidationConfig({
                            ...validationConfig,
                            validation_type: newVal,
                            python_code: validationPython,
                        });
                    }}>
                    <MenuItem value={''}>
                        <em style={{ opacity: 0.38 }}>None</em>
                    </MenuItem>
                    {Object.keys(ValidationConfigType).map((validationType) => {
                        return (
                            <MenuItem key={validationType} value={validationType}>
                                {validationType}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <TextField
                disabled={userInteractionDisabled || validationConfig.validation_type !== ValidationConfigType.PYTHON}
                required={validationConfig.validation_type === ValidationConfigType.PYTHON}
                id="python-val"
                label="Python Code Validation"
                fullWidth
                multiline
                rows={'4'}
                value={validationConfig.python_code || ''}
                onChange={(event) => {
                    setValidationConfig({
                        ...validationConfig,
                        python_code: event.target.value,
                    });
                }}
            />
        </React.Fragment>
    );
};
