import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { authGetFetcher } from '../../util/fetcher';
import config from '../../config';
import { RDbResp, CUDDbDialogType, ListDbResp } from './types';
import { NonSuccessPlaceHolder } from '../../swissTech/components/Loading';
import { Grid, IconButton, List, ListItem, ListItemButton, ListItemText, Paper } from '@mui/material';
import { Add, Edit } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { CUDDbDialog } from './CUDDbDialog';
import { FetchState, FetchStatus, hasSucceeded, initState } from '../../swissTech/fetcher';
import { useHistory } from 'react-router-dom';

export const LDb = () => {
    const history = useHistory();
    const theme = useTheme();
    const dispatch = useDispatch();

    const [lDb, setLDb] = useState<FetchState<ListDbResp>>(initState());

    useEffect(() => {
        if (lDb.status !== FetchStatus.Initialized) return;
        authGetFetcher<ListDbResp>({
            description: 'List Databases',
            baseUrl: config.backendUrl,
            url: '/db',
            withState: { fetchState: lDb, setFetchState: setLDb },
            siteAlertOnError: { dispatch: dispatch },
        });
    }, [dispatch, lDb]);

    const [openCUDDialog, setOpenCUDDialog] = React.useState<CUDDbDialogType>({ open: false });

    const openUDialog = (existing: RDbResp) => {
        setOpenCUDDialog({ open: true, db: existing });
    };

    const openCDialog = () => {
        setOpenCUDDialog({ open: true });
    };

    const onCancel = () => {
        setOpenCUDDialog({ open: false });
    };

    const onCreate = (newDb: RDbResp) => {
        let newListDb: ListDbResp = {
            dbs: [...lDb.data!.dbs, newDb],
        };
        setLDb({
            ...lDb,
            data: newListDb,
        });
        setOpenCUDDialog({ open: false });
    };

    const onDelete = (deleteDb: RDbResp) => {
        let newListDb: ListDbResp = {
            dbs: lDb.data!.dbs.filter((db) => db.name !== deleteDb.name),
        };
        setLDb({
            ...lDb,
            data: newListDb,
        });
        setOpenCUDDialog({ open: false });
    };

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={11} style={{ backgroundColor: 'white', paddingTop: 0 }}>
                    <h1 style={{ margin: 0 }}>Databases</h1>
                </Grid>
                <Grid
                    item
                    xs={1}
                    paddingRight={theme.spacing(2)}
                    style={{ backgroundColor: 'white', paddingTop: 0, textAlign: 'right' }}>
                    <IconButton size={'small'} color="primary" aria-label="create database" onClick={openCDialog}>
                        <Add fontSize={'large'} />
                    </IconButton>
                </Grid>
                <Grid container justifyContent={'center'} paddingTop={theme.spacing(5)}>
                    <Grid item xs={10} style={{ backgroundColor: 'white' }}>
                        <NonSuccessPlaceHolder
                            minHeight={'200px'}
                            fetchState={lDb}
                            retryOnError={() => {
                                setLDb(initState());
                            }}
                        />
                        {hasSucceeded(lDb.status) && lDb.data!.dbs.length > 0 && (
                            <Paper>
                                <List>
                                    {lDb.data!.dbs.map((db) => {
                                        return (
                                            <ListItem
                                                disablePadding
                                                key={db.name}
                                                secondaryAction={
                                                    <IconButton
                                                        aria-label="edit database"
                                                        size="medium"
                                                        onClick={() => openUDialog(db)}>
                                                        <Edit fontSize="inherit" />
                                                    </IconButton>
                                                }>
                                                <ListItemButton
                                                    onClick={() => {
                                                        history.push(`/dbs/${db.id}`);
                                                    }}>
                                                    <ListItemText
                                                        primary={db.name}
                                                        primaryTypographyProps={{
                                                            style: { color: theme.palette.primary.main },
                                                        }}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Paper>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {openCUDDialog.open && (
                <CUDDbDialog
                    openCUDDialog={openCUDDialog}
                    handleCancel={onCancel}
                    handleCreate={onCreate}
                    handleUpdate={onCancel}
                    handleDelete={onDelete}
                />
            )}
        </React.Fragment>
    );
};
