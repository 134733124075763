import React, {useCallback, useRef} from 'react';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import {CellValueChangedEvent, GridReadyEvent} from 'ag-grid-community/dist/lib/events';
import {EditableCallbackParams, ValueSetterParams} from 'ag-grid-community/dist/lib/entities/colDef';
import {RowNode} from 'ag-grid-community/dist/lib/entities/rowNode';
import {GetContextMenuItemsParams} from 'ag-grid-community/dist/lib/entities/gridOptions';
import {ReadTableResp} from '../table/types';
import 'ag-grid-enterprise';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {DatabaseTitleHeaderHeight, TableData} from '../types';
import {ValidationErrorDialog, ValidationErrorDialogState} from './ValidationErrorDialog';

type Props = {
    validationErrorDialogState: ValidationErrorDialogState;
    handleCloseValidationErrorDialog: () => void;
    creatingNewRow: boolean;
    tableConfig: ReadTableResp;
    tableData: TableData;
    valueSetter: (params: ValueSetterParams) => boolean;
    setTableData: (newVal: TableData) => void;
    deleteRow: (rowIdx: number) => void;

    onCellValueChanged(event: CellValueChangedEvent): void;
    onGridReady(event: GridReadyEvent): void;
};

export const UGrid = ({
                          validationErrorDialogState,
                          handleCloseValidationErrorDialog,
                          creatingNewRow,
                          tableData,
                          deleteRow,
                          tableConfig,
                          valueSetter,
                          onGridReady,
                      }: Props) => {
    const getContextMenuItems = (params: GetContextMenuItemsParams) => {
        if (tableData.length === 0) return [];
        return [
            {
                name: creatingNewRow ? 'Delete Newly Created Row' : 'Delete row',
                action: function () {
                    deleteRow(params.node!.rowIndex!);
                },
            },
        ];
    };

    // I don't understand how this works but if we don't wrap the postSort in some way to
    // dynamically get the props the creatingNewRow does not change to true when we are in create mode
    // https://www.ag-grid.com/react-data-grid/fine-tuning/
    function useDynamicCallback(callback: any) {
        const ref = useRef();
        ref.current = callback;
        // @ts-ignore
        return useCallback((...args) => ref.current.apply(this, args), []);
    }

    const postSort = useDynamicCallback((rowNodes: Array<RowNode>) => {
        if (!creatingNewRow) return; // We only need to modify the sort order when we are creating a new row
        // If for some reason we get here (which we shouldn't) just don't do any sorting
        // This will happen if we switch table tabs which we shouldn't allow it
        if (rowNodes.length === 0) return;
        const newRowIdx = rowNodes.findIndex((rowNode) => rowNode.id === '0') // The new row always has id of 0
        let node = rowNodes[newRowIdx];
        // Delete the element and re-add it in the beginning
        rowNodes.splice(newRowIdx, 1);
        rowNodes.unshift(node);
    })

    return (
        // 52px is the TableFooter, 43px is the Header with the Database name and buttons
        // Actually not really sure why this math adds app
        <div style={{height: `calc(100% - 38px - ${DatabaseTitleHeaderHeight}px)`, backgroundColor: 'red'}}>
            <div className="ag-theme-alpine" style={{height: '100%', width: '100%'}}>
                <AgGridReact
                    getContextMenuItems={getContextMenuItems}
                    defaultColDef={{
                        editable: true,
                    }}
                    postSort={postSort}
                    // rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.DeepValueCheck}
                    // editType={'fullRow'}
                    rowData={tableData}
                    // onCellEditingStopped={(event) => {
                    //     console.log('onCellEditingStopped');
                    //     console.log(event);
                    // }}
                    // onCellValueChanged={onCellValueChanged}
                    // onColumnValueChanged={(event) => {
                    //     console.log('onColumnValueChanged');
                    //     console.log(event);
                    // }}
                    onGridReady={onGridReady}>
                    {tableConfig.columns.map((columnConfig) => {
                        let agGridColumnConfigs: any = {};
                        return (
                            <AgGridColumn
                                sortable={true}
                                valueGetter={(params) => {
                                    return params.data[columnConfig.name];
                                }}
                                valueSetter={valueSetter}
                                editable={(params: EditableCallbackParams) => {
                                    if (!creatingNewRow) return true;
                                    return params.node.rowIndex === 0;
                                }}
                                key={columnConfig.name}
                                field={columnConfig.name}
                                headerName={columnConfig.name}
                                {...agGridColumnConfigs}
                            />
                        );
                    })}
                </AgGridReact>
            </div>
            <ValidationErrorDialog state={validationErrorDialogState} handleClose={handleCloseValidationErrorDialog}/>
        </div>
    );
};
