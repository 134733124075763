import React from 'react';
import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import { ValidationConfig } from './types';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { ValidationConfigFields } from './ValidationConfigFields';
import { DatabaseConfigFields, ManageColumnConfigFields } from './DatabaseConfigFields';

type Props = {
    userInteractionDisabled: boolean;
    manageColumnConfigFields: ManageColumnConfigFields;
    disableEditColumnName?: boolean;
    columnNameToolTipTitle?: string;
    autofocus?: boolean;
};

export const ColumnConfigFields = ({
    userInteractionDisabled,
    manageColumnConfigFields,
    disableEditColumnName,
    columnNameToolTipTitle,
    autofocus,
}: Props) => {
    let [editColumnConfig, setEditColumnConfig] = manageColumnConfigFields;

    const [subTab, setSubTab] = React.useState('1');

    const handleSubTabSelection = (event: React.SyntheticEvent, newValue: string) => {
        setSubTab(newValue);
    };

    const setValidationConfig = (newVal: ValidationConfig) => {
        setEditColumnConfig({ ...editColumnConfig, validation_config: newVal });
    };

    return (
        <TabContext value={subTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleSubTabSelection} aria-label="lab API tabs example">
                    <Tab label="Database Config" value="1" />
                    <Tab label="Validations" value="2" />
                </TabList>
            </Box>
            <TabPanel value="1">
                <DatabaseConfigFields
                    userInteractionDisabled={userInteractionDisabled}
                    manageColumnConfigFields={manageColumnConfigFields}
                    disableEditColumnName={disableEditColumnName}
                    columnNameToolTipTitle={columnNameToolTipTitle}
                    autofocus={autofocus}
                />
            </TabPanel>
            <TabPanel value="2">
                <ValidationConfigFields
                    userInteractionDisabled={userInteractionDisabled}
                    manageValidationConfig={[editColumnConfig.validation_config, setValidationConfig]}
                />
            </TabPanel>
        </TabContext>
    );
};
