import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Step,
    StepLabel,
    Stepper,
} from '@mui/material';
import React, { useState } from 'react';
import { ButtonSpinner } from '../../swissTech/components/ButtonSpinner';
import { authPostFetcher } from '../../util/fetcher';
import config from '../../config';
import { EditSchemaFromUpload } from './EditSchemaFromUpload';
import { CancelRequest, FetchState, initState, isLoading } from '../../swissTech/fetcher';
import { useDispatch } from 'react-redux';
import { CreateNewTablesResp, CreateTableFromFileResp, EditCreateNewTables, MultiTableDiffResp } from './types';
import { MultipleDiffReview } from '../db/util/DiffReview';

type Props = {
    db_id: string;
    onClose: () => void;
    onCreate: (data: CreateTableFromFileResp) => void;
};

export const CFromUploadTableDialog = ({ db_id, onClose, onCreate }: Props) => {
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useState<number>(0);
    const [uploadFile, setUploadFile] = useState<File | undefined>(undefined);
    const [cancelRequest, setCancelRequest] = useState<{ c: CancelRequest } | undefined>(undefined);
    const [createNewTableResp, setCreateNewTableResp] = useState<FetchState<CreateNewTablesResp>>(initState());
    const [editTablesConfig, setEditTablesConfig] = useState<EditCreateNewTables>({ tables: [] });

    const [multTableDiffResp, setMultTableDiffResp] = useState<FetchState<MultiTableDiffResp>>(initState());
    const [submitNewTableFileResp, setSubmitNewTableFileResp] = useState<FetchState<CreateTableFromFileResp>>(
        initState()
    );

    const userInteractionDisabled =
        isLoading(createNewTableResp.status) ||
        isLoading(multTableDiffResp.status) ||
        isLoading(submitNewTableFileResp.status);

    const overrideOnClose = () => {
        if (userInteractionDisabled) return;
        onClose();
    };

    const goToPrevStep = () => {
        switch (activeStep) {
            case 1:
                if (cancelRequest !== undefined) {
                    cancelRequest.c('Canceled from user');
                    setCancelRequest(undefined);
                }
                setUploadFile(undefined);
                setActiveStep(0);
                break;
            case 2:
                setActiveStep(0); // Go back 2 steps
                break;
            case 3:
                setActiveStep(2); // Go back to editing
                break;
        }
    };

    const setUploadFileNextStep = (f: File) => {
        setUploadFile(f);
        setActiveStep(1);
        const formData = new FormData();
        formData.append('file', f);
        authPostFetcher<CreateNewTablesResp>({
            description: `Upload File`,
            baseUrl: config.backendUrl,
            url: `/db/${db_id}`,
            params: {
                file_upload: true,
            },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
            siteAlertOnError: { dispatch: dispatch },
            withState: { fetchState: createNewTableResp, setFetchState: setCreateNewTableResp },
            onStart: (_cancelRequest) => {
                setCancelRequest({ c: _cancelRequest }); // For some reason if we don't store it in a dict it executes the function
            },
            onFail: () => {
                setUploadFile(undefined);
                setActiveStep(0);
            },
            onSuccess: (data) => {
                setActiveStep(2); // Can't use +1 logic as the value is frozen
                setEditTablesConfig(data);
            },
            onFinal: () => setCancelRequest(undefined),
        });
    };

    const verifyConfig = () => {
        const formData = new FormData();
        formData.append('file', uploadFile!);

        const json = JSON.stringify(editTablesConfig);
        const blob = new Blob([json], {
            type: 'application/json',
        });
        formData.append('json_data', blob);

        authPostFetcher<MultiTableDiffResp>({
            description: `Verify Config`,
            baseUrl: config.backendUrl,
            url: `/db/${db_id}`,
            params: {
                verify_upload_config: true,
            },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
            siteAlertOnError: { dispatch: dispatch },
            withState: { fetchState: multTableDiffResp, setFetchState: setMultTableDiffResp },
            onStart: (_cancelRequest) => {
                setCancelRequest({ c: _cancelRequest }); // For some reason if we don't store it in a dict it executes the function
            },
            onSuccess: (data) => {
                setActiveStep(3); // Can't use +1 logic as the value is frozen
            },
            onFinal: () => setCancelRequest(undefined),
        });
    };

    const submitConfigAndData = () => {
        const formData = new FormData();
        formData.append('file', uploadFile!);

        const json = JSON.stringify(editTablesConfig);
        const blob = new Blob([json], {
            type: 'application/json',
        });
        formData.append('json_data', blob);

        authPostFetcher<CreateTableFromFileResp>({
            description: `Submit Config`,
            baseUrl: config.backendUrl,
            url: `/db/${db_id}`,
            params: {
                submit_upload_config: true,
            },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
            siteAlertOnError: { dispatch: dispatch },
            withState: { fetchState: submitNewTableFileResp, setFetchState: setSubmitNewTableFileResp },
            siteAlertOnSuccess: {
                dispatch: dispatch,
                msg: `${editTablesConfig.tables.length} Tables Created and data loaded`,
            },
            onSuccess: (data) => {
                onCreate(data);
            },
        });
    };

    return (
        <Dialog fullWidth maxWidth={'xl'} open={true} onClose={overrideOnClose}>
            <DialogTitle>Create Tables from File</DialogTitle>
            <DialogContent>
                <Stepper>
                    <Step active={activeStep === 0} completed={activeStep > 0}>
                        <StepLabel>Upload</StepLabel>
                    </Step>
                    <Step active={activeStep === 1} completed={activeStep > 1}>
                        <StepLabel>Generate Configuration</StepLabel>
                    </Step>
                    <Step active={activeStep === 2} completed={activeStep > 2}>
                        <StepLabel>Edit Configuration</StepLabel>
                    </Step>
                    <Step active={activeStep === 3} completed={activeStep > 3}>
                        <StepLabel>Verify Configuration</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Complete</StepLabel>
                    </Step>
                </Stepper>
                <Box p={2}>
                    <UploadStep activeStep={activeStep} setUploadFile={setUploadFileNextStep} />
                    {activeStep === 1 && (
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress />
                        </div>
                    )}
                    {[2, 3].includes(activeStep) && (
                        <EditSchemaFromUpload
                            manageTablesConfig={[editTablesConfig, setEditTablesConfig]}
                            userInteractionDisabled={userInteractionDisabled || activeStep === 3}
                        />
                    )}
                    {activeStep === 3 && <MultipleDiffReview diffResp={multTableDiffResp.data!} />}
                </Box>
            </DialogContent>
            <DialogActions>
                {activeStep !== 0 && (
                    <ButtonSpinner
                        onClick={() => {
                            goToPrevStep();
                        }}
                        disabled={userInteractionDisabled}
                        loading={false}
                        buttonProps={{ variant: 'text', style: { marginRight: 'auto' } }}>
                        Back
                    </ButtonSpinner>
                )}
                {activeStep === 2 && (
                    <ButtonSpinner
                        onClick={verifyConfig}
                        disabled={userInteractionDisabled}
                        loading={isLoading(multTableDiffResp.status)}>
                        Verify
                    </ButtonSpinner>
                )}
                {activeStep === 3 && (
                    <ButtonSpinner
                        onClick={() => {
                            submitConfigAndData();
                        }}
                        disabled={userInteractionDisabled}
                        loading={isLoading(submitNewTableFileResp.status)}>
                        Submit
                    </ButtonSpinner>
                )}
            </DialogActions>
        </Dialog>
    );
};

type UploadStepProps = {
    activeStep: number;
    setUploadFile: (f: File) => void;
};

const UploadStep = ({ activeStep, setUploadFile }: UploadStepProps) => {
    if (activeStep !== 0) return null;
    return (
        <div style={{ textAlign: 'center' }}>
            <Button variant="contained" component="label">
                Upload File
                <input
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    hidden
                    onChange={(event) => {
                        setUploadFile(event.target.files![0]);
                    }}
                />
            </Button>
        </div>
    );
};
