import {AuthID, signInAction, signOutAction} from '../store/auth';
import {useDispatch} from 'react-redux';
import React, {useEffect} from 'react';
import {ErrorContentType, getFetcher} from '../swissTech/fetcher';
import config from '../config';
import {signIn, signOut} from '../util/auth';
import {CenteredCircularProgress} from '../swissTech/components/Loading';
import {authGetFetcher} from '../util/fetcher';
import {siteErrorOpenAction} from '../swissTech/store/siteAlerts';

const FAILED_SIGN_IN_REFRESH_TIME = 5000; // in milliseconds

type AuthGoogleProps = {
    googleJWT: string;
};

interface CreateAppJWTResp {
    app_jwt: string;
    auth_id: AuthID;
}

export const AuthGoogle = ({googleJWT}: AuthGoogleProps) => {
    const dispatch = useDispatch();
    useEffect(() => {
        getFetcher<CreateAppJWTResp>({
            description: 'Create App JWT',
            baseUrl: config.backendUrl,
            headers: {
                'BluGrid-UI-App-Client-ID': config.appClientId,
                'BluGrid-Google-JWT': googleJWT,
            },
            url: '/create_app_jwt',
            onStart: () => {
            },
            onSuccess: (res) => {
                signIn(res.app_jwt);
                dispatch(signInAction(res.auth_id));
            },
            onFail: (err) => {
                signInFailed(dispatch, err);
            },
        });
    }, [dispatch, googleJWT]);

    return <CenteredCircularProgress/>;
};

type AuthAppProps = {
    appJWT: string;
};

interface VerifyAppJWTResp {
    auth_id: AuthID;
}

export const AuthApp = ({appJWT}: AuthAppProps) => {
    const dispatch = useDispatch();
    useEffect(() => {
        authGetFetcher<VerifyAppJWTResp>({
            description: 'Verify App JWT',
            baseUrl: config.backendUrl,
            url: '/verify_auth',
            onStart: () => {
            },
            onSuccess: (res) => {
                dispatch(signInAction(res.auth_id));
            },
            onFail: (err) => {
                signInFailed(dispatch, err);
            },
        });
    }, [dispatch, appJWT]);

    return <CenteredCircularProgress/>;
};

const signInFailed = <T extends any>(dispatch: any, err: ErrorContentType<T>) => {
    signOut(); // Remove session Storage
    dispatch(signOutAction()); // Even though we will refresh clear the auth Redux store
    let errMsg = `Failed to authenticate with Google. Signing out forcefully in ${
        FAILED_SIGN_IN_REFRESH_TIME / 1000
    } seconds. (reason: ${err.userMsg})`;
    dispatch(
        siteErrorOpenAction({
            message: errMsg,
        })
    );
    console.error(errMsg);
    // This will cause the React/Window logic to recalculate and refresh
    setTimeout(() => {
        window.location.reload();
    }, FAILED_SIGN_IN_REFRESH_TIME);
};
