import React, { ReactChild } from 'react';
import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { green } from '@mui/material/colors';

type Props = {
    disabled: boolean;
    loading: boolean;
    onClick: () => void;
    buttonProps?: ButtonProps;
    children: ReactChild;
};

export const ButtonSpinner = ({ onClick, disabled, loading, buttonProps, children }: Props) => {
    return (
        <Button disabled={disabled || loading} variant="contained" color="primary" onClick={onClick} {...buttonProps}>
            {loading && (
                <CircularProgress
                    size={24}
                    style={{
                        color: green[500],
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: -12,
                        marginLeft: -12,
                    }}
                />
            )}
            {children}
        </Button>
    );
};
