import { MultiTableDiffResp, SingleTableDiffResp } from '../../table/types';
import React, { useEffect, useRef, useState } from 'react';

type SingleDiffReviewProps = {
    diffResp: SingleTableDiffResp;
};

export const SingleDiffReview = ({ diffResp }: SingleDiffReviewProps) => {
    return (
        <div>
            {diffResp.sql !== null && <pre>{diffResp.sql}</pre>}
            {diffResp.validation_commands !== null && <pre>{diffResp.validation_commands}</pre>}
        </div>
    );
};

type MultipleDiffReviewProps = {
    diffResp: MultiTableDiffResp;
};

export const MultipleDiffReview = ({ diffResp }: MultipleDiffReviewProps) => {
    const reviewDiffElement = useRef<HTMLDivElement>(null);
    const [scrolled, setScrolled] = useState<boolean>(false);
    useEffect(() => {
        if (scrolled) return;
        setScrolled(true);
        setTimeout(() => {
            reviewDiffElement.current!.scrollIntoView({ behavior: 'smooth' });
        }, 100);
    }, [scrolled]);
    return (
        <div ref={reviewDiffElement}>
            {diffResp.tables.map((tableDiffResp, idx) => {
                return <SingleDiffReview key={idx} diffResp={tableDiffResp} />;
            })}
        </div>
    );
};
