import {useHistory, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {CUDTableDialog} from '../table/CUDTableDialog';
import {FetchStatus, hasSucceeded, initState} from '../../swissTech/fetcher';
import {authGetFetcher} from '../../util/fetcher';
import {ReadDbResp} from './types';
import config from '../../config';
import {useDispatch} from 'react-redux';
import {CenteredCircularProgress} from '../../swissTech/components/Loading';
import {CreateTableFromFileResp, CUDTableDialogType, DeleteTableResp, ReadTableResp} from '../table/types';
import {DbInfo} from '../types';
import {TableHome} from '../table/TableHome';
import {DbTableFooter, HandleSetSelectedTableTab} from './DbTableFooter';
import {CFromUploadTableDialog} from '../table/CFromUploadTableDialog';

export const RDb = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    let params = useParams<{ db_id: string; table_name: string | undefined }>();
    let db_id = params.db_id;
    let params_table_name = params.table_name;

    // ================== State Variables ==================

    let [openCUDTableDialog, setOpenCUDTableDialog] = useState<CUDTableDialogType>({openTablePosition: -1});
    let [openCFromUploadTableDialog, setOpenCFromUploadTableDialog] = useState<boolean>(false);

    const [dbInfo, setDbInfo] = useState<DbInfo>(initState());
    const [selectedTableTab, setSelectedTableTab] = useState<number>(-1);

    const [createTableMenuAnchorEl, setCreateTableMenuAnchorEl] = React.useState<null | HTMLElement>(null);

    const [creatingNewRow, setCreatingNewRow] = useState<boolean>(false);

    // ================== Effect Changes ==================
    useEffect(() => {
        if (dbInfo.status !== FetchStatus.Initialized) return;
        authGetFetcher<ReadDbResp>({
            description: 'Read Database Config',
            baseUrl: config.backendUrl,
            url: `/dbs/${db_id}`,
            withState: {fetchState: dbInfo, setFetchState: setDbInfo},
            siteAlertOnError: {dispatch: dispatch},
            onSuccess: (data) => {
                if (data.tables.length === 0) return;
                let selectedTab = 0;
                if (params_table_name) {
                    let idx = data.tables.findIndex((table) => table.name === params_table_name);
                    if (idx !== -1) selectedTab = idx;
                }
                setSelectedTableTab(selectedTab);
                history.push(`/dbs/${db_id}/${data.tables[selectedTab].name}`);
            },
        });
    }, [dispatch, db_id, dbInfo, params_table_name, history]);

    // ================== Create Table Callbacks ==================

    const handleCreateTableAnchorElClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setCreateTableMenuAnchorEl(event.currentTarget);
    };
    const handleCreateTableAnchorElClose = () => {
        setCreateTableMenuAnchorEl(null);
    };

    const handleCreateTableAnchorUploadFile = () => {
        handleCreateTableAnchorElClose();
        setOpenCFromUploadTableDialog(true);
        // const formData = new FormData();
        // formData.append('file', f);
        // handleCreateTableAnchorElClose();
        // authPostFetcher<any>({
        //     description: `Upload File`,
        //     baseUrl: config.backendUrl,
        //     url: `/db/${db_id}`,
        //     params: {
        //         file_upload: true,
        //     },
        //     headers: {
        //         'Content-Type': 'multipart/form-data',
        //     },
        //     data: formData,
        //     onSuccess: (data) => {
        //         console.log(data);
        //     },
        // });
    };

    const handleSetSelectedTableTab: HandleSetSelectedTableTab = (newValue, overrideTableName) => {
        setSelectedTableTab(newValue);
        if (overrideTableName !== undefined) {
            if (overrideTableName === null) {
                history.push(`/dbs/${db_id}`);
            } else {
                history.push(`/dbs/${db_id}/${overrideTableName}`);
            }
        } else {
            history.push(`/dbs/${db_id}/${dbInfo.data!.tables[newValue].name}`);
        }
    };

    const closeCUDTableDialog = () => setOpenCUDTableDialog({openTablePosition: -1});

    // ================== Table Dialog callbacks ==================

    const onTableCreate = (newTableConfig: ReadTableResp) => {
        let newTableIdx = dbInfo.data!.tables.length;
        setDbInfo({
            ...dbInfo,
            data: {
                ...dbInfo.data!,
                tables: [...dbInfo.data!.tables, newTableConfig],
            },
        });
        handleSetSelectedTableTab(newTableIdx, newTableConfig.name);
        closeCUDTableDialog();
    };

    const onTableUpdate = (updateTableConfig: ReadTableResp) => {
        let newTables: Array<ReadTableResp> = Array.from(dbInfo.data!.tables, (table) => {
            if (table.id !== updateTableConfig.id) return table;
            return updateTableConfig;
        });
        setDbInfo({
            ...dbInfo,
            data: {
                ...dbInfo.data!,
                tables: newTables,
            },
        });
        closeCUDTableDialog();
    };

    const onTableDelete = (deleteTableResp: DeleteTableResp) => {
        let newTables = dbInfo.data!.tables.filter((table) => table.id !== deleteTableResp.id);

        // State will not have updated yet so we need to override to the first table name we know is valid
        // Also if we deleted all the tables then we should remove the table name parameter all together
        let urlTableName: string | null = newTables.length === 0 ? null : newTables[0].name;
        let selectedIndexTab: number = newTables.length === 0 ? -1 : 0;
        handleSetSelectedTableTab(selectedIndexTab, urlTableName);

        // Need to delay just a bit otherwise the TableFooter show the scroll util sometimes
        setTimeout(() => {
            setDbInfo({
                ...dbInfo,
                data: {
                    ...dbInfo.data!,
                    tables: newTables,
                },
            });
            closeCUDTableDialog();
        }, 100);
    };

    const onTableCreateFromFile = (newTablesConfig: CreateTableFromFileResp) => {
        let newTables = [...dbInfo.data!.tables, ...newTablesConfig.tables];
        let newTableIdx = newTables.length - 1;
        setDbInfo({
            ...dbInfo,
            data: {
                ...dbInfo.data!,
                tables: newTables,
            },
        });
        handleSetSelectedTableTab(newTableIdx, newTables[newTableIdx].name);
        setOpenCFromUploadTableDialog(false);
    };

    if (!hasSucceeded(dbInfo.status)) return <CenteredCircularProgress/>;

    return (
        <div style={{height: '100%'}}>
            {selectedTableTab !== -1 && (
                <TableHome dbName={dbInfo.data!.name} tableConfig={dbInfo.data!.tables[selectedTableTab]}
                           creatingNewRow={creatingNewRow} setCreatingNewRow={setCreatingNewRow}/>
            )}
            <DbTableFooter
                createTableAnchorEl={createTableMenuAnchorEl}
                handleCreateTableAnchorElClick={handleCreateTableAnchorElClick}
                handleCreateTableAnchorUploadFile={handleCreateTableAnchorUploadFile}
                handleCreateTableAnchorElClose={handleCreateTableAnchorElClose}
                setOpenCUDTableDialog={setOpenCUDTableDialog}
                dbInfo={dbInfo}
                selectedTableTab={selectedTableTab}
                handleSetSelectedTableTab={handleSetSelectedTableTab}
                creatingNewRow={creatingNewRow}
            />
            {openCUDTableDialog.openTablePosition !== -1 && (
                <CUDTableDialog
                    db_id={dbInfo.data!.id}
                    cUDTableDialog={openCUDTableDialog}
                    closeCUDTableDialog={closeCUDTableDialog}
                    onCreateSuccess={onTableCreate}
                    onUpdateSuccess={onTableUpdate}
                    onDeleteSuccess={onTableDelete}
                />
            )}
            {openCFromUploadTableDialog && (
                <CFromUploadTableDialog
                    db_id={db_id}
                    onClose={() => setOpenCFromUploadTableDialog(false)}
                    onCreate={onTableCreateFromFile}
                />
            )}
        </div>
    );
};
