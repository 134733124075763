import { SnackbarOrigin } from '@mui/material';
import { AlertColor } from '@mui/material/Alert';

// ==================== ACTIONS ====================

const ERROR_OPEN = 'ERROR_OPEN';
const ERROR_CLOSE = 'ERROR_CLOSE';
const SUCCESS_OPEN = 'SUCCESS_OPEN';
const SUCCESS_CLOSE = 'SUCCESS_CLOSE';

interface SiteOpenPayloadType {
    message: string;
    autoHideDuration?: number;
}

interface SiteOpenActionType {
    type: typeof ERROR_OPEN | typeof SUCCESS_OPEN;
    payload: SiteOpenPayloadType;
}

interface SiteCloseActionType {
    type: typeof ERROR_CLOSE | typeof SUCCESS_CLOSE;
}

export const siteErrorOpenAction = (payload: SiteOpenPayloadType): SiteOpenActionType => ({
    type: ERROR_OPEN,
    payload: payload,
});

export const siteErrorCloseAction = (): SiteCloseActionType => ({
    type: ERROR_CLOSE,
});

export const siteSuccessOpenAction = (payload: SiteOpenPayloadType): SiteOpenActionType => ({
    type: SUCCESS_OPEN,
    payload: payload,
});

export const siteSuccessCloseAction = (): SiteCloseActionType => ({
    type: SUCCESS_CLOSE,
});

type SiteErrorActionTypes = SiteOpenActionType | SiteCloseActionType;

export type siteCloseActionFuncType = typeof siteErrorCloseAction | typeof siteSuccessCloseAction;

// ==================== Store ====================

export interface SiteAlertState {
    message: string;
    autoHideDuration: number;

    severity: AlertColor;
    anchorOrigin: SnackbarOrigin;
}

export interface SiteAlertsState {
    successAlert: SiteAlertState | null;
    errorAlert: SiteAlertState | null;
}

const initialState: SiteAlertsState = { successAlert: null, errorAlert: null };
const siteAlerts = (state = initialState, action: SiteErrorActionTypes): SiteAlertsState => {
    switch (action.type) {
        case ERROR_OPEN:
            return {
                ...state,
                errorAlert: {
                    severity: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    autoHideDuration:
                        action.payload.autoHideDuration === undefined ? 10000 : action.payload.autoHideDuration,
                    message: action.payload.message,
                },
            };
        case ERROR_CLOSE:
            return {
                ...state,
                errorAlert: null,
            };
        case SUCCESS_OPEN:
            return {
                ...state,
                successAlert: {
                    severity: 'success',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                    autoHideDuration:
                        action.payload.autoHideDuration === undefined ? 5000 : action.payload.autoHideDuration,
                    message: action.payload.message,
                },
            };
        case SUCCESS_CLOSE:
            return {
                ...state,
                successAlert: null,
            };
        default:
            return state;
    }
};

export default siteAlerts;
