import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Tab,
    Tabs,
    TextField,
} from '@mui/material';
import React, { RefObject, useRef, useState } from 'react';
import { Close } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { ColumnConfigFields } from '../column/ColumnConfigFields';
import { useTheme } from '@mui/material/styles';
import { FetchState, initState, isLoading } from '../../swissTech/fetcher';
import { ButtonSpinner } from '../../swissTech/components/ButtonSpinner';
import { authDeleteFetcher, authPostFetcher, authPutFetcher } from '../../util/fetcher';
import config from '../../config';
import { useDispatch } from 'react-redux';
import { ColumnType, CUColumnConfig, NewEntryDefaultType } from '../column/types';
import { CUDTableDialogType, CUTableConfig, DeleteTableResp, ReadTableResp, SingleTableDiffResp } from './types';

type ManageColumnTab = [number, (newVal: number) => void];
type ManageEditTableConfig = [CUTableConfig, (newVal: CUTableConfig) => void];

enum HTTPActions {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
}

enum UserStep {
    Edit,
    Review,
}

interface ActionState {
    isCreate: boolean;
    action?: HTTPActions;
    currStep: UserStep;
}

type CreateTableDialogProps = {
    db_id: string;
    cUDTableDialog: CUDTableDialogType;
    closeCUDTableDialog: () => void;
    onCreateSuccess: (newTableConfig: ReadTableResp) => void;
    onUpdateSuccess: (updateTableConfig: ReadTableResp) => void;
    onDeleteSuccess: (deleteTableResp: DeleteTableResp) => void;
};

export const CUDTableDialog = ({
    db_id,
    cUDTableDialog,
    closeCUDTableDialog,
    onCreateSuccess,
    onUpdateSuccess,
    onDeleteSuccess,
}: CreateTableDialogProps) => {
    const dispatch = useDispatch();

    const [actionState, setActionState] = useState<ActionState>({
        isCreate: cUDTableDialog.tableConfig === undefined,
        currStep: UserStep.Edit,
    });
    let tmpCurrData: CUTableConfig = actionState.isCreate
        ? { db_id: db_id, columns: [] }
        : { ...cUDTableDialog.tableConfig! };
    let [editTableConfig, setEditTableConfig] = useState<CUTableConfig>(tmpCurrData);

    const [cUDryRynTableConfig, setCUDryRynTableConfig] = useState<FetchState<SingleTableDiffResp>>(initState());

    const [cUTableConfig, setCUTableConfig] = useState<FetchState<ReadTableResp>>(initState());

    const [dTableConfig, setDTableConfig] = useState<FetchState<DeleteTableResp>>(initState());

    const reviewDiffElement = useRef<HTMLDivElement>(null);

    const onReview = (action: HTTPActions) => {
        let httpFunc = authPutFetcher;
        let url = `/db/${db_id}/${editTableConfig.name}`;
        if (action === HTTPActions.CREATE) {
            httpFunc = authPostFetcher;
            url = `/db/${db_id}`;
        }
        if (action === HTTPActions.DELETE) httpFunc = authDeleteFetcher;
        setActionState({ ...actionState, action: action });
        httpFunc<SingleTableDiffResp>({
            description: `${action} Table Dry Run`,
            baseUrl: config.backendUrl,
            url: url,
            params: {
                dry_run: true,
            },
            data: editTableConfig,
            withState: { fetchState: cUDryRynTableConfig, setFetchState: setCUDryRynTableConfig },
            siteAlertOnError: { dispatch: dispatch },
            onSuccess: (d) => {
                // Potential race condition so make sure to put the action again in case actionState has not updated yet
                setActionState({ ...actionState, currStep: UserStep.Review, action: action });
                // We need to add a bit of delay for the state to update otherwise for example the DELETE state change is too quick
                setTimeout(() => {
                    reviewDiffElement.current!.scrollIntoView({ behavior: 'smooth' });
                }, 100);
            },
        });
    };

    const onCreate = () => {
        authPostFetcher<ReadTableResp>({
            description: 'Create Table',
            baseUrl: config.backendUrl,
            url: `/db/${db_id}`,
            data: editTableConfig,
            withState: { fetchState: cUTableConfig, setFetchState: setCUTableConfig },
            siteAlertOnError: { dispatch: dispatch },
            siteAlertOnSuccess: { dispatch: dispatch, msg: 'Table Created' },
            onSuccess: (data) => {
                onCreateSuccess(data);
            },
        });
    };

    const onUpdate = () => {
        authPutFetcher<ReadTableResp>({
            description: 'Update Table',
            baseUrl: config.backendUrl,
            url: `/db/${db_id}/${editTableConfig.name!}`,
            data: editTableConfig,
            withState: { fetchState: cUTableConfig, setFetchState: setCUTableConfig },
            siteAlertOnError: { dispatch: dispatch },
            siteAlertOnSuccess: { dispatch: dispatch, msg: 'Table Updated' },
            onSuccess: (data) => {
                onUpdateSuccess(data);
            },
        });
    };

    const onDelete = () => {
        authDeleteFetcher<DeleteTableResp>({
            description: 'Delete Table',
            baseUrl: config.backendUrl,
            url: `/db/${db_id}/${editTableConfig.name!}`,
            data: {
                id: editTableConfig.id,
                name: editTableConfig.name,
            },
            withState: { fetchState: dTableConfig, setFetchState: setDTableConfig },
            siteAlertOnError: { dispatch: dispatch },
            siteAlertOnSuccess: { dispatch: dispatch, msg: 'Table Deleted' },
            onSuccess: (data) => {
                onDeleteSuccess(data);
            },
        });
    };

    let mainButtonIsLoading =
        (isLoading(cUDryRynTableConfig.status) && actionState.action !== HTTPActions.DELETE) ||
        isLoading(cUTableConfig.status) ||
        isLoading(dTableConfig.status);
    let deleteButtonIsLoading = isLoading(cUDryRynTableConfig.status) && actionState.action === HTTPActions.DELETE;

    let mainButtonAction: () => void = () => {
        onReview(HTTPActions.UPDATE);
    };
    let mainButtonText = 'Next';
    if (actionState.currStep === UserStep.Edit) {
        if (actionState.isCreate)
            mainButtonAction = () => {
                onReview(HTTPActions.CREATE);
            };
    } else {
        switch (actionState.action) {
            case HTTPActions.CREATE:
                mainButtonAction = onCreate;
                mainButtonText = 'Confirm Create';
                break;
            case HTTPActions.UPDATE:
                mainButtonAction = onUpdate;
                mainButtonText = 'Confirm Update';
                break;
            case HTTPActions.DELETE:
                mainButtonAction = onDelete;
                mainButtonText = 'Confirm Delete';
                break;
            default:
                throw Error('In the Review Step we should know which action we are on');
        }
    }

    let userInteractionDisabled =
        isLoading(cUDryRynTableConfig.status) || isLoading(cUTableConfig.status) || isLoading(dTableConfig.status);
    return (
        <Dialog
            fullWidth
            maxWidth={'xl'}
            open={true}
            onClose={() => {
                if (userInteractionDisabled) return;
                closeCUDTableDialog();
            }}>
            <DialogTitle>
                <span>{actionState.isCreate ? 'Create Table' : 'Edit Table'}</span>
            </DialogTitle>
            <DialogContent>
                <MakeChangesStep
                    userInteractionDisabled={userInteractionDisabled}
                    cUDTableDialog={cUDTableDialog}
                    reviewDiffElement={reviewDiffElement}
                    actionState={actionState}
                    manageEditTableConfig={[editTableConfig, setEditTableConfig]}
                    cUDryRynTableConfig={cUDryRynTableConfig}
                />
            </DialogContent>
            <DialogActions>
                {!actionState.isCreate && (
                    <ButtonSpinner
                        onClick={() => {
                            onReview(HTTPActions.DELETE);
                        }}
                        disabled={userInteractionDisabled || actionState.currStep !== UserStep.Edit}
                        loading={deleteButtonIsLoading}
                        buttonProps={{ color: 'error', style: { marginRight: 'auto' } }}>
                        Delete
                    </ButtonSpinner>
                )}
                <ButtonSpinner
                    onClick={() => {
                        if (actionState.currStep === UserStep.Edit) return closeCUDTableDialog();
                        setCUDryRynTableConfig(initState());
                        setActionState({ ...actionState, currStep: UserStep.Edit });
                    }}
                    disabled={userInteractionDisabled}
                    loading={false}
                    buttonProps={{ variant: 'text' }}>
                    {actionState.currStep === UserStep.Edit ? 'Cancel' : 'Back'}
                </ButtonSpinner>
                <ButtonSpinner
                    onClick={mainButtonAction}
                    disabled={userInteractionDisabled || !editTableConfig.name}
                    loading={mainButtonIsLoading}>
                    {mainButtonText}
                </ButtonSpinner>
            </DialogActions>
        </Dialog>
    );
};

type MakeChangesStepProps = {
    userInteractionDisabled: boolean;
    cUDTableDialog: CUDTableDialogType;
    reviewDiffElement: RefObject<HTMLDivElement>;
    actionState: ActionState;
    cUDryRynTableConfig: FetchState<SingleTableDiffResp>;
    manageEditTableConfig: ManageEditTableConfig;
};

const MakeChangesStep = ({
    userInteractionDisabled,
    cUDTableDialog,
    reviewDiffElement,
    actionState,
    manageEditTableConfig,
    cUDryRynTableConfig,
}: MakeChangesStepProps) => {
    const [selectedColumnTab, setSelectedColumnTab] = useState<number>(
        cUDTableDialog.tableConfig === undefined ? -1 : 0
    );
    let [editTableConfig, setEditTableConfig] = manageEditTableConfig;
    userInteractionDisabled = userInteractionDisabled || actionState.currStep !== UserStep.Edit;
    return (
        <div>
            <TextField
                autoFocus
                disabled={userInteractionDisabled}
                required
                id="name"
                label="Table name"
                type="text"
                fullWidth
                value={editTableConfig.name || ''}
                onChange={(event) => {
                    setEditTableConfig({
                        ...editTableConfig,
                        name: event.target.value,
                    });
                }}
            />
            <Divider style={{ marginTop: '25px' }}>
                <Button
                    variant="contained"
                    disabled={userInteractionDisabled}
                    onClick={() => {
                        let newColumnConfig: CUColumnConfig =
                            editTableConfig.columns.length === 0
                                ? {
                                      name: 'id',
                                      column_type: ColumnType.UUID,
                                      required: true,
                                      is_primary: true,
                                      // position: 0,
                                      new_entry_config: { default_type: NewEntryDefaultType.UUID },
                                      validation_config: {},
                                  }
                                : {
                                      name: `new_column_${editTableConfig.columns.length + 1}`,
                                      column_type: ColumnType.Text,
                                      required: true,
                                      length: 16,
                                      is_primary: false,
                                      // position: editTableConfig.columns.length,
                                      new_entry_config: {},
                                      validation_config: {},
                                  };
                        let columnConfigs: Array<CUColumnConfig> = [...editTableConfig.columns, newColumnConfig];
                        setEditTableConfig({
                            ...editTableConfig,
                            columns: columnConfigs,
                        });
                        setSelectedColumnTab(columnConfigs.length - 1);
                    }}>
                    Add Column
                </Button>
            </Divider>
            <TabContext value={selectedColumnTab.toString()}>
                <ColumnTabs
                    userInteractionDisabled={userInteractionDisabled}
                    tableConfig={editTableConfig}
                    manageColumnTab={[selectedColumnTab, setSelectedColumnTab]}
                    deleteColumnConfig={(idxToDelete) => {
                        let columnConfigs: Array<CUColumnConfig> = editTableConfig.columns.filter(
                            (_, idx) => idx !== idxToDelete
                        );
                        setEditTableConfig({
                            ...editTableConfig,
                            columns: columnConfigs,
                        });
                        setSelectedColumnTab(Math.max(0, selectedColumnTab - 1));
                    }}
                />
                {editTableConfig.columns.map((columnConfig, idx) => {
                    const setColumnConfigFields = (newVal: CUColumnConfig) => {
                        let columnConfigs: Array<CUColumnConfig> = [...editTableConfig.columns];
                        columnConfigs[idx] = newVal;
                        setEditTableConfig({
                            ...editTableConfig,
                            columns: columnConfigs,
                        });
                    };
                    return (
                        <TabPanel key={idx} value={idx.toString()}>
                            <ColumnConfigFields
                                userInteractionDisabled={userInteractionDisabled}
                                manageColumnConfigFields={[columnConfig, setColumnConfigFields]}
                            />
                        </TabPanel>
                    );
                })}
            </TabContext>
            <div ref={reviewDiffElement}>
                {actionState.currStep === UserStep.Review && (
                    <React.Fragment>
                        <Divider />
                        {cUDryRynTableConfig.data!.sql !== null && <pre>{cUDryRynTableConfig.data!.sql}</pre>}
                        {cUDryRynTableConfig.data!.validation_commands !== null && (
                            <pre>{cUDryRynTableConfig.data!.validation_commands}</pre>
                        )}
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

type ColumnTabsProps = {
    userInteractionDisabled: boolean;
    tableConfig: CUTableConfig;
    manageColumnTab: ManageColumnTab;
    deleteColumnConfig: (idx: number) => void;
};

const ColumnTabs = ({ userInteractionDisabled, tableConfig, manageColumnTab, deleteColumnConfig }: ColumnTabsProps) => {
    const theme = useTheme();
    let [selectedColumnTab, handleSetSelectedColumnTab] = manageColumnTab;
    if (tableConfig.columns.length === 0) return null;
    return (
        <Tabs
            value={selectedColumnTab.toString()}
            onChange={(_: React.SyntheticEvent, newValue: string) => {
                handleSetSelectedColumnTab(parseInt(newValue));
            }}
            variant="scrollable"
            scrollButtons={true}
            aria-label="Tab list of available columns to select"
            style={{ marginTop: '20px' }}>
            {tableConfig.columns.map((columnConfig, idx) => {
                let tabStyle: any = {
                    borderTop: `1px solid ${theme.palette.divider}`,
                };
                let isLast = idx === tableConfig.columns.length - 1;
                if (!isLast) {
                    tabStyle['borderRight'] = `1px solid ${theme.palette.divider}`;
                }
                return (
                    <Tab
                        style={tabStyle}
                        key={idx}
                        component="div"
                        value={idx.toString()}
                        label={
                            <span>
                                {columnConfig.name}
                                <IconButton
                                    disabled={userInteractionDisabled}
                                    style={{ width: 18, height: 18, margin: '0px 0px 0px 20px' }}
                                    onClick={() => {
                                        // Don't allow to delete/close tab/column when the tab/column is not selected
                                        if (selectedColumnTab !== idx) return;
                                        deleteColumnConfig(idx);
                                    }}>
                                    <Close />
                                </IconButton>
                            </span>
                        }
                    />
                );
            })}
        </Tabs>
    );
};
