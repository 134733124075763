import { TextField, TextFieldProps, Tooltip } from '@mui/material';
import React from 'react';

export type TooltipPlacements =
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';

type Props = TextFieldProps & {
    tooltip_title?: string;
    tooltip_placement?: TooltipPlacements;
};

export const TooltipTextField = (props: Props) => {
    return (
        <Tooltip
            title={props.tooltip_title || ''}
            placement={props.tooltip_placement ? props.tooltip_placement : 'top'}>
            <span>
                <TextField {...props} />
            </span>
        </Tooltip>
    );
};
