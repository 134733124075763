import React from 'react';
import { ColumnType, CUColumnConfig } from './types';
import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';
import { TitleField } from '../../util/components/TitleField';

export type ManageColumnConfigFields = [CUColumnConfig, (newVal: CUColumnConfig) => void];

type Props = {
    userInteractionDisabled: boolean;
    disableEditColumnName?: boolean;
    columnNameToolTipTitle?: string;
    autofocus?: boolean;
    manageColumnConfigFields: ManageColumnConfigFields;
};

export const DatabaseConfigFields = ({
    userInteractionDisabled,
    disableEditColumnName,
    columnNameToolTipTitle,
    autofocus,
    manageColumnConfigFields,
}: Props) => {
    let [editColumnConfig, setEditColumnConfig] = manageColumnConfigFields;
    return (
        <React.Fragment>
            <TitleField
                autofocus={autofocus}
                label={'Column Name'}
                value={editColumnConfig.name}
                disabled={userInteractionDisabled || disableEditColumnName === true}
                tooltip_title={columnNameToolTipTitle}
                tooltip_placement={'top-start'}
                setTitle={(name) => {
                    setEditColumnConfig({
                        ...editColumnConfig,
                        name: name,
                    });
                }}
            />
            <FormControl variant={'standard'} sx={{ my: 2, minWidth: 120 }} disabled={userInteractionDisabled}>
                <InputLabel id="column-type-select-label">Column Type</InputLabel>
                <Select
                    disabled={userInteractionDisabled}
                    labelId="column-type-select-label"
                    id="column-type-select"
                    value={editColumnConfig.column_type}
                    label="Column Type"
                    onChange={(event) => {
                        let newVal = ColumnType[event.target.value as keyof typeof ColumnType];

                        if (newVal)
                            setEditColumnConfig({
                                ...editColumnConfig,
                                column_type: newVal,
                                length: getColumnLength(newVal, editColumnConfig.length),
                                required: getRequired(newVal, editColumnConfig.required),
                            });
                    }}>
                    {Object.keys(ColumnType).map((columnType) => {
                        return (
                            <MenuItem key={columnType} value={columnType}>
                                {columnType}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <TextField
                disabled={userInteractionDisabled || !columnTypeHasLength(editColumnConfig.column_type!)}
                required={columnTypeHasLength(editColumnConfig.column_type!)}
                id="length"
                label="Length"
                type="number"
                fullWidth
                value={editColumnConfig.length || ''}
                InputProps={{ inputProps: { min: 2, max: 256 } }}
                onChange={(event) => {
                    let val = parseInt(event.target.value);
                    if (isNaN(val)) {
                        setEditColumnConfig({
                            ...editColumnConfig,
                            length: undefined,
                        });
                        return;
                    }
                    if (val > 256) val = 256;
                    if (val < 0) val = 8;
                    setEditColumnConfig({
                        ...editColumnConfig,
                        length: val,
                    });
                }}
            />
            <FormControlLabel
                control={
                    <Switch
                        disabled={userInteractionDisabled || isRequired(editColumnConfig.column_type)}
                        inputProps={{ 'aria-label': 'Switch Required' }}
                        checked={editColumnConfig.required}
                        onChange={() => {
                            setEditColumnConfig({
                                ...editColumnConfig,
                                required: !editColumnConfig.required,
                            });
                        }}
                    />
                }
                label="Is value Required"
            />
            <FormControlLabel
                control={
                    <Switch
                        disabled={userInteractionDisabled}
                        inputProps={{ 'aria-label': 'Switch Primary Key' }}
                        checked={editColumnConfig.is_primary}
                        onChange={() => {
                            setEditColumnConfig({
                                ...editColumnConfig,
                                is_primary: !editColumnConfig.is_primary,
                            });
                        }}
                    />
                }
                label="Is Primary Key"
            />
        </React.Fragment>
    );
};

const getColumnLength = (columnType: ColumnType, length: number | undefined): number | undefined => {
    switch (columnType) {
        case ColumnType.Text:
            return length ? length : 18;
        default:
            return undefined;
    }
};

const getRequired = (columnType: ColumnType, required: boolean): boolean => {
    return isRequired(columnType) ? true : required;
};

const isRequired = (columnType: ColumnType): boolean => {
    return [ColumnType.AutoIncrement, ColumnType.UUID, ColumnType.CreatedAt, ColumnType.UpdatedAt].includes(columnType);
};

const columnTypeHasLength = (columnType: ColumnType): boolean => getColumnLength(columnType, undefined) !== undefined;
