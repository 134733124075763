import {LicenseManager} from 'ag-grid-enterprise';

export enum Env {
    development = 'development',
    production = 'production',
    staging = 'staging'
}

class Config {
    env: Env;
    backendUrl: string;
    appClientId: string;
    googleAuthClientId: string;
    agGridLicenceKey: string | undefined;

    constructor(env: Env, backendUrl: string | undefined, appClientId: string | undefined, googleAuthClientId: string | undefined,
                agGridLicenceKey: string | undefined) {
        this.env = env;
        if (backendUrl === undefined || backendUrl === '') throw Error('Backend Url is undefined');
        this.backendUrl = backendUrl;
        if (appClientId === undefined || appClientId === '') throw Error('App Client ID is undefined');
        this.appClientId = appClientId;
        if (googleAuthClientId === undefined || googleAuthClientId === '')
            throw Error(`Google Auth Client ID is undefined`);
        this.googleAuthClientId = googleAuthClientId;
        // Set the licence for AGGrid
        this.agGridLicenceKey = agGridLicenceKey
        if (agGridLicenceKey === undefined || agGridLicenceKey === '') {
            console.error('AG Grid Licence Key is not set')
        } else {
            LicenseManager.setLicenseKey(agGridLicenceKey);
        }
    }

    is_dev() {
        return this.env === Env.development
    }

    is_staging() {
        return this.env === Env.staging
    }

    is_prod() {
        return this.env === Env.production
    }
}

if (process.env.NODE_ENV !== process.env.REACT_APP_SWISS_TECH_ENV) {
    throw Error(
        `NODE_ENV '${process.env.NODE_ENV}' and SWISS_TECH_ENV '${process.env.REACT_APP_SWISS_TECH_ENV}' don't match`
    );
}

if (process.env.REACT_APP_SWISS_TECH_ENV === undefined) {
    throw Error(
        `Env REACT_APP_SWISS_TECH_ENV is not defined`
    );
}

if ((Env as any)[process.env.REACT_APP_SWISS_TECH_ENV] === undefined) {
    const envs = Object.keys(Env).filter((item) => {
        return isNaN(Number(item));
    });
    throw Error(
        `Env REACT_APP_SWISS_TECH_ENV '${process.env.REACT_APP_SWISS_TECH_ENV}' is not of valid Env values (${envs.join(', ')})`
    );
}

const config = new Config(
    (Env as any)[process.env.REACT_APP_SWISS_TECH_ENV!],
    process.env.REACT_APP_BACKEND_URL,
    process.env.REACT_APP_APP_CLIENT_ID,
    process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
    process.env.REACT_APP_AG_GRID_LICENCE_KEY
)

export default config;
