import { combineReducers, createStore } from 'redux';
import { useSelector as _useSelector } from 'react-redux';
import siteAlerts from '../swissTech/store/siteAlerts';
import auth from './auth';

const rootReducer = combineReducers({
    siteAlerts: siteAlerts,
    auth: auth,
});

export type RootState = ReturnType<typeof rootReducer>;

export function useSelector<T>(fn: (store: RootState) => T): T {
    return _useSelector<RootState, T>(fn);
}

export const store = createStore(rootReducer);
