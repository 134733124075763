export const GOOGLE_JWT_SESSION_KEY = 'googleJWT';
export const APP_JWT_SESSION_KEY = 'appJWT';

export function getLocalStorageOrDefault(key: string, defaultValue: any) {
    const stored = localStorage.getItem(key);
    if (!stored) {
        return defaultValue;
    }
    return JSON.parse(stored);
}

export const signOut = () => {
    localStorage.removeItem(APP_JWT_SESSION_KEY);
    localStorage.removeItem(GOOGLE_JWT_SESSION_KEY);
};

export const signIn = (appJWT: string) => {
    localStorage.setItem('appJWT', JSON.stringify(appJWT));
    localStorage.removeItem(GOOGLE_JWT_SESSION_KEY);
};
