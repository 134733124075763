import { ErrorContentType, FetchConfigBase, fetcher, MethodBase } from '../swissTech/fetcher';
import { APP_JWT_SESSION_KEY, getLocalStorageOrDefault, signOut } from './auth';
import { Dispatch } from 'react';
import { siteErrorOpenAction, siteSuccessOpenAction } from '../swissTech/store/siteAlerts';

interface HelperConfig {
    siteAlertOnError?: {
        dispatch: Dispatch<any>;
    };
    siteAlertOnSuccess?: {
        dispatch: Dispatch<any>;
        msg: string;
    };
}

export interface AuthFetchConfigCallbacks<T> {
    onSuccess?: (data: T) => void;
    onFail?: (errMsg: ErrorContentType<T>) => void;
}

interface AuthMethodFetchConfig<T> extends FetchConfigBase<T>, MethodBase, AuthFetchConfigCallbacks<T>, HelperConfig {}

function authFetcher<T>(methodFetchConfig: AuthMethodFetchConfig<T>): void {
    let appJWT = getLocalStorageOrDefault(APP_JWT_SESSION_KEY, null);
    methodFetchConfig.headers = {
        'BluGrid-App-JWT': appJWT,
        ...methodFetchConfig.headers,
    };
    return fetcher({
        ...methodFetchConfig,
        onStart: (cancelRequest) => {
            if (methodFetchConfig.onStart) methodFetchConfig.onStart(cancelRequest);
        },
        onFail: (err) => {
            // At some point our App JWT will expire at which point gracefully logout the user
            if (err.status === 440) {
                signOut(); // Remove session Storage
                window.location.reload();
                return;
            }
            if (methodFetchConfig.siteAlertOnError)
                methodFetchConfig.siteAlertOnError.dispatch(siteErrorOpenAction({ message: err.debugMsg }));
            if (methodFetchConfig.onFail) methodFetchConfig.onFail(err);
        },
        onSuccess: (data: T) => {
            if (methodFetchConfig.siteAlertOnSuccess)
                methodFetchConfig.siteAlertOnSuccess.dispatch(
                    siteSuccessOpenAction({ message: methodFetchConfig.siteAlertOnSuccess.msg })
                );
            if (methodFetchConfig.onSuccess) methodFetchConfig.onSuccess(data);
        },
    });
}

export type BaseResp = {};

interface AuthFetchConfig<T> extends FetchConfigBase<T>, AuthFetchConfigCallbacks<T>, HelperConfig {}

export function authGetFetcher<T>(fetchConfig: AuthFetchConfig<T>): void {
    authFetcher({
        ...fetchConfig,
        method: 'GET',
    });
}

export function authPostFetcher<T>(fetchConfig: AuthFetchConfig<T>): void {
    if (fetchConfig.headers && !('Content-Type' in fetchConfig.headers))
        fetchConfig.headers['Content-Type'] = 'application/json';
    authFetcher({
        ...fetchConfig,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            ...fetchConfig.headers,
        },
    });
}

export function authPutFetcher<T>(fetchConfig: AuthFetchConfig<T>): void {
    if (fetchConfig.headers && !('Content-Type' in fetchConfig.headers))
        fetchConfig.headers['Content-Type'] = 'application/json';
    authFetcher({
        ...fetchConfig,
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            ...fetchConfig.headers,
        },
    });
}

export function authDeleteFetcher<T>(fetchConfig: AuthFetchConfig<T>): void {
    if (fetchConfig.headers && !('Content-Type' in fetchConfig.headers))
        fetchConfig.headers['Content-Type'] = 'application/json';
    authFetcher({
        ...fetchConfig,
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            ...fetchConfig.headers,
        },
    });
}
