import React from 'react';
import {CUDTableDialogType} from '../table/types';
import {DbInfo} from '../types';
import {useTheme} from '@mui/material/styles';
import {AppBar, IconButton, Tab, Tabs, Toolbar, Tooltip} from '@mui/material';
import {Add, Settings} from '@mui/icons-material';
import {CreateTableMenu} from './CreateTableMenu';
import {hasSucceeded} from '../../swissTech/fetcher';

export type HandleSetSelectedTableTab = (newValue: number, overrideTableName?: string | null) => void;

type TableFooterProps = {
    createTableAnchorEl: HTMLElement | null;
    handleCreateTableAnchorElClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleCreateTableAnchorUploadFile: () => void;
    handleCreateTableAnchorElClose: () => void;
    setOpenCUDTableDialog: (newVal: CUDTableDialogType) => void;
    dbInfo: DbInfo;
    selectedTableTab: number;
    handleSetSelectedTableTab: HandleSetSelectedTableTab;
    creatingNewRow: boolean;
};

export const DbTableFooter = ({
                                  createTableAnchorEl,
                                  handleCreateTableAnchorElClick,
                                  handleCreateTableAnchorUploadFile,
                                  handleCreateTableAnchorElClose,
                                  setOpenCUDTableDialog,
                                  dbInfo,
                                  selectedTableTab,
                                  handleSetSelectedTableTab,
                                  creatingNewRow,
                              }: TableFooterProps) => {
    const theme = useTheme();
    return (
        <AppBar
            position={'fixed'}
            style={{
                top: 'auto',
                bottom: 0,
                backgroundColor: '#f1f3f4',
                color: 'black',
                borderTop: '2px solid #d7d9db',
            }}>
            <Toolbar
                style={{
                    minHeight: '40px',
                    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
                }}>
                <div style={{backgroundColor: 'clear', width: '100%', height: '100%'}}>
                    <IconButton style={{verticalAlign: 'initial'}} onClick={handleCreateTableAnchorElClick}>
                        <Add fontSize={'medium'}/>
                    </IconButton>
                    <CreateTableMenu
                        anchorEl={createTableAnchorEl}
                        handleClose={handleCreateTableAnchorElClose}
                        handleCreateTable={() => {
                            setOpenCUDTableDialog({openTablePosition: dbInfo.data!.tables.length});
                            handleCreateTableAnchorElClose();
                        }}
                        handleUploadFile={handleCreateTableAnchorUploadFile}
                    />
                    <TableTabs
                        setOpenCUDTableDialog={setOpenCUDTableDialog}
                        dbInfo={dbInfo}
                        handleSetSelectedTableTab={handleSetSelectedTableTab}
                        selectedTableTab={selectedTableTab}
                        creatingNewRow={creatingNewRow}
                    />
                </div>
            </Toolbar>
        </AppBar>
    );
};

type TableTabsProps = {
    setOpenCUDTableDialog: (newVal: CUDTableDialogType) => void;
    dbInfo: DbInfo;
    handleSetSelectedTableTab: HandleSetSelectedTableTab;
    selectedTableTab: number;
    creatingNewRow: boolean;
};

const TableTabs = ({
                       setOpenCUDTableDialog,
                       dbInfo,
                       handleSetSelectedTableTab,
                       selectedTableTab,
                       creatingNewRow
                   }: TableTabsProps) => {
    const theme = useTheme();
    if (!hasSucceeded(dbInfo.status) || selectedTableTab === -1) return null;
    let creatingNewRowTooltip = creatingNewRow ? 'First submit the new row before switching tables' : ''
    return (
        <Tabs
            value={selectedTableTab}
            onChange={(_: React.SyntheticEvent, newValue: number) => {
                handleSetSelectedTableTab(newValue);
            }}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Tab list of available tables to select"
            style={{display: 'inline-flex', height: '40px', minHeight: '40px'}}>
            {dbInfo.data!.tables.map((table, idx) => {
                let sxHover = {};
                let tabIsSelected = selectedTableTab === idx;
                if (tabIsSelected)
                    sxHover = {
                        '&:hover': {
                            fill: theme.palette.primary.main,
                        },
                    };
                return (
                    <Tab
                        // Needed to enable Tooltip on disabled element
                        // https://github.com/mui/material-ui/issues/12597#issuecomment-455244379
                        style={{pointerEvents: "auto"}}
                        disableRipple
                        key={idx}
                        value={idx}
                        disabled={creatingNewRow}
                        label={
                            <Tooltip title={creatingNewRowTooltip} placement={'top'}>
                                <div>
                                    <span style={{verticalAlign: 'super'}}>{table.name}</span>
                                    <Settings
                                        sx={{
                                            transform: 'scale(0.8)',
                                            fill: '#cccccc',
                                            ...sxHover,
                                        }}
                                        onClick={() => {
                                            if (!tabIsSelected) return;
                                            setOpenCUDTableDialog({
                                                openTablePosition: idx,
                                                tableConfig: table,
                                            });
                                        }}
                                    />
                                </div>
                            </Tooltip>
                        }
                    />
                );
            })}
        </Tabs>
    );
};
