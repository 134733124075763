import CircularProgress from '@mui/material/CircularProgress';
import { Button, Paper, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { Error } from '@mui/icons-material';
import { FetchState, hasFailed, hasSucceeded, isPreparing } from '../fetcher';
import { styled } from '@mui/material/styles';

type CenteredCircularProgressProps = {
    msg?: string;
};

export const CenteredCircularProgress = (props: CenteredCircularProgressProps) => {
    return (
        <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
            <div style={{ textAlign: 'center' }}>
                <CircularProgress />
            </div>
            <div style={{ marginTop: '25px' }}>{props.msg && <span>{props.msg}</span>}</div>
        </div>
    );
};

const LargerTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500,
        padding: 10,
    },
});

type PaperLoadingPlaceHolderProps<T> = {
    minHeight: string;
    fetchState: FetchState<T>;
    loadingMsg?: string;
    errMsgOverride?: string;
    retryOnError?: () => void;
};

export const NonSuccessPlaceHolder = <T extends any>(props: PaperLoadingPlaceHolderProps<T>) => {
    if (hasSucceeded(props.fetchState.status)) return null;
    let customStyles: any = {
        display: 'table',
        textAlign: 'center',
        width: '100%',
        minHeight: props.minHeight,
        // Add standard margins that Grids have so that the loading and actual papers have the same borders
        marginTop: '12px',
        marginBottom: '12px',
    };
    return (
        <Paper style={customStyles}>
            <div style={{ display: 'table-cell', verticalAlign: 'middle', paddingLeft: '15px', paddingRight: ' 15px' }}>
                {isPreparing(props.fetchState.status) && (
                    <span>
                        <CircularProgress style={{ marginBottom: '10px' }} size={20} />
                        {props.loadingMsg && <div>{props.loadingMsg}</div>}
                    </span>
                )}
                {hasFailed(props.fetchState.status) && (
                    <div>
                        <Error />
                        <LargerTooltip title={props.fetchState.error?.debugMsg || ''} placement={'top'}>
                            <span style={{ marginLeft: '10px', marginRight: '10px' }}>
                                Failed to Load {props.fetchState.error?.description}
                            </span>
                        </LargerTooltip>
                        {props.retryOnError !== undefined && (
                            <Button
                                variant={'text'}
                                onClick={() => {
                                    if (props.retryOnError) props.retryOnError();
                                }}>
                                Retry
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </Paper>
    );
};
