import React from 'react';
import { CUTableConfig, EditCreateNewTables } from './types';
import { TabPanel } from '@mui/lab';
import { CUColumnConfig } from '../column/types';
import { ColumnConfigFields } from '../column/ColumnConfigFields';
import { TitleField } from '../../util/components/TitleField';
import { SheetTabPanel } from '../../util/components/SheetTabPanel';

type ManageTablesConfig = [EditCreateNewTables, (newVal: EditCreateNewTables) => void];

type Props = {
    manageTablesConfig: ManageTablesConfig;
    userInteractionDisabled: boolean;
};

export const EditSchemaFromUpload = ({ manageTablesConfig, userInteractionDisabled }: Props) => {
    const [editTablesConfig, setEditTablesConfig] = manageTablesConfig;
    const tableNames = Array.from(editTablesConfig.tables, (tableConfig) => tableConfig.name!);

    return (
        <SheetTabPanel
            tabs={tableNames}
            disableDelete={true}
            closeTooltipTitle={'To delete a table edit the spreadsheet and re-upload it'}>
            {editTablesConfig.tables.map((tableConfig, tableIdx) => {
                const columnNames = Array.from(tableConfig.columns, (columnConfig) => columnConfig.name!);
                return (
                    <TabPanel key={tableIdx} value={tableIdx.toString()}>
                        <TitleField
                            label={'Table Name'}
                            value={tableConfig.name}
                            disabled={true}
                            tooltip_title={'To edit the table name edit the uploaded SpreadSheet and re-upload'}
                            tooltip_placement={'top-start'}
                            setTitle={() => {}}
                        />
                        <div style={{ marginTop: '20px', marginBottom: '10px' }} />
                        <SheetTabPanel
                            tabs={columnNames}
                            disableDelete={true}
                            closeTooltipTitle={'To delete a column edit the spreadsheet and re-upload it'}>
                            {tableConfig.columns.map((columnConfig, columnIdx) => {
                                const setColumnConfigFields = (newVal: CUColumnConfig) => {
                                    let columnConfigs: Array<CUColumnConfig> = [...tableConfig.columns];
                                    columnConfigs[columnIdx] = newVal;
                                    let tableConfigs: Array<CUTableConfig> = [...editTablesConfig.tables];
                                    tableConfigs[tableIdx].columns = columnConfigs;
                                    setEditTablesConfig({
                                        ...editTablesConfig,
                                        tables: tableConfigs,
                                    });
                                };
                                return (
                                    <TabPanel key={columnIdx} value={columnIdx.toString()}>
                                        <ColumnConfigFields
                                            userInteractionDisabled={userInteractionDisabled}
                                            manageColumnConfigFields={[columnConfig, setColumnConfigFields]}
                                            disableEditColumnName={true}
                                            columnNameToolTipTitle={
                                                'To edit the column name edit the uploaded SpreadSheet and re-upload'
                                            }
                                        />
                                    </TabPanel>
                                );
                            })}
                        </SheetTabPanel>
                        {/*<ColumnConfigFields*/}
                        {/*    userInteractionDisabled={userInteractionDisabled}*/}
                        {/*    manageColumnConfigFields={[columnConfig, setColumnConfigFields]}*/}
                        {/*/>*/}
                    </TabPanel>
                );
            })}
        </SheetTabPanel>
    );
};
