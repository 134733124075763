import React from 'react';
import { TooltipPlacements, TooltipTextField } from '../../swissTech/components/TooltipTextField';

type Props = {
    label: string;
    value?: string;
    autofocus?: boolean;
    disabled: boolean;
    tooltip_title?: string;
    tooltip_placement: TooltipPlacements;
    setTitle: (name?: string) => void;
};

export const TitleField = ({
    label,
    value,
    autofocus,
    disabled,
    tooltip_title,
    tooltip_placement,
    setTitle,
}: Props) => {
    return (
        <TooltipTextField
            variant={'standard'}
            tooltip_title={tooltip_title}
            tooltip_placement={tooltip_placement}
            autoFocus={autofocus}
            disabled={disabled}
            required={!disabled}
            id="name"
            label={label}
            type="text"
            fullWidth
            value={value || ''}
            onChange={(event) => {
                setTitle(event.target.value);
            }}
        />
    );
};
