import React from 'react';
import { DataValidationRespType } from './types';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

export interface ValidationErrorDialogState {
    status: DataValidationRespType;
    title?: string;
    errorMsg?: string;
}

export type Props = {
    state: ValidationErrorDialogState;
    handleClose: () => void;
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children?: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ValidationErrorDialog = ({ state, handleClose }: Props) => {
    let title = '';
    let errorMsg = '';
    if (state.status !== DataValidationRespType.NoError) {
        title = state.title!;
        errorMsg = state.errorMsg!;
    }
    return (
        <div>
            {state.status !== DataValidationRespType.NoError && (
                <Dialog
                    open={true}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="error-dialog-validation">
                    <DialogTitle>
                        <Alert variant="filled" severity="error">
                            {title}
                        </Alert>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="error-dialog-validation">{errorMsg}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>OK</Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
};
