// ==================== ACTIONS ====================

const SIGN_IN = 'SIGN_IN';

interface SignInActionType {
    type: typeof SIGN_IN;
    payload: AuthID;
}

export const signInAction = (payload: AuthID): SignInActionType => ({
    type: SIGN_IN,
    payload: payload,
});

const SIGN_OUT = 'SIGN_OUT';

interface SignOutActionType {
    type: typeof SIGN_OUT;
}

export const signOutAction = (): SignOutActionType => ({
    type: SIGN_OUT,
});

type AuthActionTypes = SignInActionType | SignOutActionType;

// ==================== Store ====================

export enum AuthStatus {
    NOT_SIGNED_IN = 'NOT_SIGNED_IN',
    SIGNED_IN = 'SIGNED_IN',
}

export interface AuthState {
    auth_status: AuthStatus;
    auth_id?: AuthID;
}

export interface AuthID {
    client_app: AuthClientApp;
    auth_user: AuthUser;
}

interface AuthClientApp {
    client_app_id: string;
    permissions: Array<string>;
}

enum AuthUserProviderType {
    GOOGLE = 'GOOGLE',
}

export interface AuthUser {
    provider_type: AuthUserProviderType;
    service_id: string;
    email: string;
    first_name: string;
    family_name: string;
    name: string;
}

const initialState: AuthState = {
    auth_status: AuthStatus.NOT_SIGNED_IN,
};

const auth = (state = initialState, action: AuthActionTypes): AuthState => {
    switch (action.type) {
        case SIGN_IN:
            return {
                auth_status: AuthStatus.SIGNED_IN,
                auth_id: action.payload,
            };
        case SIGN_OUT:
            return initialState;
        default:
            return state;
    }
};

export default auth;
