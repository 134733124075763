export enum ColumnType {
    Text = 'Text',
    Integer = 'Integer',
    Double = 'Double',
    Date = 'Date',
    DateTime = 'DateTime',
    Boolean = 'Boolean',

    // Magic Values
    AutoIncrement = 'AutoIncrement',
    UUID = 'UUID',
    CreatedAt = 'CreatedAt',
    UpdatedAt = 'UpdatedAt',
}

export interface BaseColumnResp {
    name: string;
    column_type: ColumnType;
    length: number;
    required: boolean;
    is_primary: boolean;

    new_entry_config: NewEntryConfig;
    validation_config: ValidationConfig;
}

export interface ReadColumnResp extends BaseColumnResp {
    id: string;
    table_id: string;
    position: number;
}

export interface CreateNewColumnResp extends BaseColumnResp {}

export enum NewEntryDefaultType {
    VALUE = 'VALUE',
    TODAY = 'TODAY',
    UUID = 'UUID',
    // SQL in the future?
}

export interface NewEntryConfig {
    default_type?: NewEntryDefaultType;
    default_val?: string;
}

export enum ValidationConfigType {
    PYTHON = 'PYTHON',
}

export interface ValidationConfig {
    validation_type?: ValidationConfigType;
    python_code?: string;
}

export interface CUColumnConfig {
    id?: string; // If Create Column no ID
    name?: string;
    table_id?: string; // If Create Table no Table ID
    column_type: ColumnType;
    length?: number;
    required: boolean;
    is_primary: boolean;
    // position: number;

    new_entry_config: NewEntryConfig;
    validation_config: ValidationConfig;
}
