import React from 'react';
import config from './config';

const SignIn = () => {
    return (
        <div
            style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
            }}>
            <div style={{ textAlign: 'center' }}>
                <div
                    id="g_id_onload"
                    data-client_id={config.googleAuthClientId}
                    data-context="signin"
                    data-ux_mode="popup"
                    data-callback="signedIn"
                    data-auto_prompt="false"
                />
                <div
                    className="g_id_signin"
                    data-shape="rectangular"
                    data-theme="filled_blue"
                    data-text="signin_with"
                    data-size="large"
                    data-locale="en-US"
                    data-logo_alignment="left"
                />
            </div>
        </div>
    );
};

export default SignIn;
