import axios, { AxiosError, Method, Canceler } from 'axios';

axios.interceptors.request.use((request) => {
    console.log(request);
    return request;
});

export type CancelRequest = (message?: string) => void;

export type ErrorData = {
    err_msg: string;
    err_type: string;
    [_: string]: any;
};

export class ErrorContentType<T> {
    fullURL: string;
    description: string;
    status: number;
    rawMsg: string;
    errorData?: ErrorData;

    debugMsg: string;
    userMsg: string;

    constructor(
        methodFetchConfig: MethodFetchConfigBase<T>,
        status: number,
        rawMsg: string,
        errorData?: ErrorData,
        debugMsg?: string,
        userMsg?: string
    ) {
        this.fullURL = `${methodFetchConfig.baseUrl}/${methodFetchConfig.url}`;
        this.description = methodFetchConfig.description;
        this.status = status;
        this.rawMsg = rawMsg;
        this.errorData = errorData;

        this.debugMsg = `[${status}](${methodFetchConfig.description}) ${debugMsg === undefined ? rawMsg : debugMsg}`;
        this.userMsg = `[${status}](${methodFetchConfig.description}) ${userMsg === undefined ? debugMsg : userMsg}`;
    }
}

function getAxiosErrorMessage<T>(error: AxiosError, methodFetchConfig: MethodFetchConfigBase<T>): ErrorContentType<T> {
    let rawMsg,
        debugMsg: string | undefined,
        userMsg: string | undefined,
        status = 520; // Web Server Returned an Unknown Error
    let errorData: ErrorData | undefined = undefined;
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        status = error.response.status;
        if (typeof error.response.data === 'string') {
            rawMsg = `Response not of JSON format`;
            userMsg = `We got non JSON format response from the server`;
        } else {
            if ('err_msg' in error.response.data) {
                errorData = error.response.data;
                rawMsg = errorData!.err_msg;
                debugMsg = `(${errorData!.err_type}) ${rawMsg}`;
                userMsg = rawMsg;
            } else {
                rawMsg = JSON.stringify(error.response.data);
                debugMsg = `We got a non standardized JSON response of ${error.response.status} with JSON payload ${rawMsg}`;
                userMsg = 'We got non standardized JSON response from the server';
            }
        }
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        rawMsg = `${error}`;
        debugMsg = `Got generic error response of ${error}`;
    } else {
        // Something happened in setting up the request that triggered an Error
        rawMsg = `${error}`;
        debugMsg = `Unknown Error occurred: ${error}`;
    }
    return new ErrorContentType(methodFetchConfig, status, rawMsg, errorData, debugMsg, userMsg);
}

export enum FetchStatus {
    Initialized = 'Initialized',
    Started = 'Started',
    Succeeded = 'Succeeded',
    Failed = 'Failed',
}

export interface FetchState<T> {
    status: FetchStatus;
    data?: T;
    error?: ErrorContentType<T>;
    cancelRequest?: CancelRequest;
}

export const initState = <T>(): FetchState<T> => {
    return { status: FetchStatus.Initialized };
};

export const isLoading = (status: FetchStatus): boolean => status === FetchStatus.Started;
export const isPreparing = (status: FetchStatus): boolean => status === FetchStatus.Initialized || isLoading(status);
export const hasSucceeded = (status: FetchStatus): boolean => status === FetchStatus.Succeeded;
export const hasFailed = (status: FetchStatus): boolean => status === FetchStatus.Failed;

export interface FetchConfigCallbacks<T> {
    onSuccess: (data: T) => void;
    onFail: (errMsg: ErrorContentType<T>) => void;
}

export interface FetchConfigBase<T> {
    description: string;
    baseUrl: string;
    url: string;
    params?: any;
    headers?: any;
    data?: any;
    withState?: {
        fetchState: FetchState<T>;
        setFetchState: (newState: FetchState<T>) => void;
    };
    onStart?: (cancelRequest: CancelRequest) => void;
    onFinal?: () => void;
}

export interface MethodBase {
    method: Method;
    headers?: any;
}

export interface MethodFetchConfigBase<T> extends FetchConfigBase<T>, FetchConfigCallbacks<T>, MethodBase {}

export function fetcher<T>(methodFetchConfig: MethodFetchConfigBase<T>): void {
    let cancel: Canceler;
    const cancelToken = new axios.CancelToken((c) => {
        cancel = c;
    });
    if (methodFetchConfig.withState)
        methodFetchConfig.withState.setFetchState({
            ...methodFetchConfig.withState.fetchState,
            status: FetchStatus.Started,
        });
    if (methodFetchConfig.onStart) {
        methodFetchConfig.onStart((message) => {
            cancel(message);
        });
    }
    let successData: T | undefined = undefined;
    axios({
        method: methodFetchConfig.method,
        baseURL: methodFetchConfig.baseUrl,
        url: methodFetchConfig.url,
        params: methodFetchConfig.params,
        headers: methodFetchConfig.headers,
        data: methodFetchConfig.data,
        cancelToken: cancelToken,
    })
        .then((response) => {
            if (response.status < 200 && response.status >= 300) {
                let rawMsg = `We were expecting return code 2xx but got ${response.status}`;
                methodFetchConfig.onFail(new ErrorContentType(methodFetchConfig, response.status, rawMsg));
                return;
            }
            if (response.data.constructor !== {}.constructor) {
                let rawMsg = JSON.stringify(response.data);
                let debugMsg = `In success response we got non JSON data ${rawMsg}`;
                let userMsg = `In success response we got non JSON data`;
                methodFetchConfig.onFail(
                    new ErrorContentType(methodFetchConfig, response.status, rawMsg, undefined, debugMsg, userMsg)
                );
                return;
            }
            if (methodFetchConfig.withState)
                methodFetchConfig.withState.setFetchState({
                    ...methodFetchConfig.withState.fetchState,
                    status: FetchStatus.Succeeded,
                    data: response.data,
                });
            // We don't want to call success here as if any run time errors occur in the success function the catch
            // bellow will be triggered and that's not right since the request to the server succeeded
            // so we just flag it that it succeeded and then execute in the final block
            successData = response.data;
        })
        .catch(function (error) {
            if (axios.isCancel(error)) {
                console.log(`Request ${methodFetchConfig.url} was cancelled`);
                return;
            }
            let err = getAxiosErrorMessage(error, methodFetchConfig);
            if (methodFetchConfig.withState)
                methodFetchConfig.withState.setFetchState({
                    ...methodFetchConfig.withState.fetchState,
                    status: FetchStatus.Failed,
                    error: err,
                });
            methodFetchConfig.onFail(err);
        })
        .then(() => {
            if (successData) methodFetchConfig.onSuccess(successData);
            if (methodFetchConfig.onFinal) methodFetchConfig.onFinal();
        });
}

export interface MethodFetchConfig<T> extends FetchConfigBase<T>, FetchConfigCallbacks<T> {}

export function getFetcher<T>(fetchConfig: MethodFetchConfig<T>): void {
    fetcher({
        ...fetchConfig,
        method: 'GET',
    });
}

export function postFetcher<T>(fetchConfig: MethodFetchConfig<T>): void {
    fetcher({
        ...fetchConfig,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...fetchConfig.headers,
        },
    });
}

export function putFetcher<T>(fetchConfig: MethodFetchConfig<T>): void {
    fetcher({
        ...fetchConfig,
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...fetchConfig.headers,
        },
    });
}
