/** @jsxImportSource @emotion/react */
import React from 'react';
import SiteAlertsBars from './swissTech/components/SiteAlerts';
import SignIn from './SignIn';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useSelector} from './store';
import {AuthState, AuthStatus} from './store/auth';
import {APP_JWT_SESSION_KEY, getLocalStorageOrDefault, GOOGLE_JWT_SESSION_KEY} from './util/auth';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import MainTopAppBar from './base/MainTopAppBar';
import {Box} from '@mui/material';
import {AuthApp, AuthGoogle} from './base/AuthComp';
import {Home} from './pages/Home';
import {RDb} from './database/db/RDb';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1884E5', // BluGrid Main Color
        },
    },
    typography: {button: {textTransform: 'none'}},
    components: {
        MuiTextField: {
            defaultProps: {variant: 'standard', margin: 'dense'},
        },
        MuiButton: {
            defaultProps: {variant: 'contained'},
        },
        MuiTooltip: {
            styleOverrides: {tooltip: {fontSize: '15px', padding: '10px', maxWidth: '500px'}},
        },
    },
});

function App() {
    const authState = useSelector<AuthState>((state) => state.auth);
    let googleJWT = getLocalStorageOrDefault(GOOGLE_JWT_SESSION_KEY, null);
    let appJWT = getLocalStorageOrDefault(APP_JWT_SESSION_KEY, null);

    // We are not signed In. Put this here before the SignedIn case for safety just in case Redux state is modified
    if (googleJWT === null && appJWT === null) {
        // We are not signed In and need to Sign In with Google
        return <SignIn/>;
    }

    if (authState.auth_status === AuthStatus.SIGNED_IN) {
        return (
            <Router>
                <MainTopAppBar/>
                <Switch>
                    <Route exact path="/">
                        <Box p={2}>
                            <Home/>
                        </Box>
                    </Route>
                    <Route exact path={['/dbs/:db_id', '/dbs/:db_id/:table_name']}>
                        <div style={{backgroundColor: 'white', height: 'calc(100% - 48px - 15px)'}}>
                            <RDb/>
                        </div>
                    </Route>
                    <Route path="/other">
                        <div>Other</div>
                    </Route>
                </Switch>
            </Router>
        );
    }

    // Bellow this point we have some authentication token and we need to authenticate it

    // We are not SignedIn at this point so follow appropriate steps to Sign In
    if (googleJWT !== null) {
        // We have SignedIn with Google. Now we need to SignIn with our Auth
        return <AuthGoogle googleJWT={googleJWT}/>;
    }

    // We have the App JWT by this point we just need to verify it
    return <AuthApp appJWT={appJWT}/>;
}

function AppTheme() {
    return (
        <ThemeProvider theme={theme}>
            <App/>
            <SiteAlertsBars/>
        </ThemeProvider>
    );
}

export default AppTheme;
