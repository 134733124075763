export enum DataValidationRespType {
    NoError = 'NoError',
    CellError = 'CellError',
    RowError = 'RowError',
}

export interface ValidateColumnResp {
    success: boolean;
    failed_msg: string | null;
}

export interface ValidateRowResp extends ValidateColumnResp {
    failed_column_name: string | null;
}
