import React, { useState } from 'react';
import { TabContext } from '@mui/lab';
import { IconButton, Tab, Tabs, Tooltip } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

interface SheetTabPanelProps {
    children?: React.ReactNode;
    tabs: Array<string>;
    disableDelete?: boolean;
    closeTooltipTitle?: string;
    onCloseTab?: (idx: number) => void;
}

export const SheetTabPanel = ({ children, tabs, disableDelete, closeTooltipTitle, onCloseTab }: SheetTabPanelProps) => {
    const theme = useTheme();
    const [selectedTab, setSelectedTab] = useState<number>(0);

    return (
        <TabContext value={selectedTab.toString()}>
            <Tabs
                value={selectedTab.toString()}
                onChange={(_: React.SyntheticEvent, newValue: string) => {
                    setSelectedTab(parseInt(newValue));
                }}
                variant="scrollable"
                scrollButtons={true}>
                {tabs.map((tabName, idx) => {
                    let isLastIdx = idx === tabs.length - 1;
                    let currTabStyle: any = {
                        borderTop: `1px solid ${theme.palette.divider}`,
                    };
                    if (!isLastIdx) {
                        currTabStyle['borderRight'] = `1px solid ${theme.palette.divider}`;
                    }
                    return (
                        <Tab
                            style={currTabStyle}
                            key={idx}
                            component="div"
                            value={idx.toString()}
                            label={
                                <span>
                                    {tabName}
                                    <Tooltip title={closeTooltipTitle || ''} placement={'top'}>
                                        <span>
                                            <IconButton
                                                disabled={disableDelete}
                                                style={{ width: 18, height: 18, margin: '0px 0px 0px 20px' }}
                                                onClick={() => {
                                                    // Don't allow to delete/close tab/table when the tab/table is not selected
                                                    if (selectedTab !== idx) return;
                                                    if (onCloseTab) onCloseTab(idx);
                                                }}>
                                                <Close />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </span>
                            }
                        />
                    );
                })}
            </Tabs>
            {children}
        </TabContext>
    );
};
