import React, {useEffect, useState} from 'react';
import {DatabaseTitleHeaderHeight, TableData} from '../types';
import {Button, Grid} from '@mui/material';
import {ReadTableResp} from './types';
import {UGrid} from '../uGrid/UGrid';
import DeleteIcon from '@mui/icons-material/Delete';
import {GridApi} from 'ag-grid-community/dist/lib/gridApi';
import {TabToNextCellParams} from 'ag-grid-community/dist/lib/entities/gridOptions';
import {CellPosition} from 'ag-grid-community/dist/lib/entities/cellPosition';
import {CellValueChangedEvent, GridReadyEvent} from 'ag-grid-community/dist/lib/events';
import {ValueSetterParams} from 'ag-grid-community/dist/lib/entities/colDef';
import {ColumnType} from '../column/types';
import {authDeleteFetcher, authGetFetcher, authPostFetcher, authPutFetcher} from '../../util/fetcher';
import config from '../../config';
import {useDispatch} from 'react-redux';
import {DataValidationRespType, ValidateColumnResp, ValidateRowResp} from '../uGrid/types';
import {ValidationErrorDialogState} from '../uGrid/ValidationErrorDialog';
import {siteSuccessOpenAction} from '../../swissTech/store/siteAlerts';
import {FetchState, hasFailed, hasSucceeded, initState, isLoading} from '../../swissTech/fetcher';
import {CenteredCircularProgress} from '../../swissTech/components/Loading';

type Props = {
    dbName: string;
    tableConfig: ReadTableResp;
    creatingNewRow: boolean;
    setCreatingNewRow: (val: boolean) => void
};

type ListTableDataResp = {
    name: string;
    data: TableData;
};

export const TableHome = ({dbName, tableConfig, creatingNewRow, setCreatingNewRow}: Props) => {
    const dispatch = useDispatch();
    const [tableDataState, setTableDataState] = useState<FetchState<ListTableDataResp>>(initState());
    // We need a separate state to manage the client state
    const [tableDataClient, setTableDataClient] = useState<TableData | undefined>(undefined);
    const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
    // const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | undefined>(undefined);
    const [validationErrorDialog, setValidationErrorDialog] = useState<ValidationErrorDialogState>({
        status: DataValidationRespType.NoError,
    });

    useEffect(() => {
        // Don't fetch if we are currently already have the table data of the table we are displaying
        if (tableDataState.data !== undefined && tableDataState.data.name === tableConfig.name) return;
        if (isLoading(tableDataState.status) || hasFailed(tableDataState.status)) return; // Avoid infinite requests
        authGetFetcher<ListTableDataResp>({
            description: `Get Table Data for ${tableConfig.name}`,
            baseUrl: config.backendUrl,
            url: `/dbs/${tableConfig.db_id}/${tableConfig.name}/data`,
            withState: {fetchState: tableDataState, setFetchState: setTableDataState},
            siteAlertOnError: {dispatch: dispatch},
            onSuccess: (data) => {
                setTableDataClient(data.data);
            },
        });
    }, [tableDataState, tableConfig, dispatch]);

    if (!hasSucceeded(tableDataState.status) || tableDataClient === undefined) return <CenteredCircularProgress/>;

    let tableData: TableData = tableDataClient!;

    const handleCloseValidationErrorDialog = () => {
        setValidationErrorDialog({status: DataValidationRespType.NoError});
    };

    const tabToNextCell = (params: TabToNextCellParams): CellPosition => {
        return params.nextCellPosition!;
    };

    const onCellValueChangedEvent = (_: CellValueChangedEvent) => {
    };

    const valueSetter = (params: ValueSetterParams): boolean => {
        let columnName = params.colDef.field!;
        // We can't use index/instanceID as the index so we look it up by name
        let columnConfig = tableConfig.columns.find((colConfig) => colConfig.name === params.colDef.field);
        if (columnConfig === undefined)
            throw Error(`Not possible for ${params.colDef.field} not to be in our column list`);
        let newVal: null | string = params.newValue === undefined ? null : params.newValue;
        let row: number = params.node!.rowIndex!;

        // We don't want to be passing empty strings, just make those empty
        if (newVal === '') newVal = null;

        let parsedNewVal: null | string | number = newVal;
        if (columnConfig.column_type === ColumnType.Double && newVal !== null) {
            parsedNewVal = parseInt(newVal);
            if (isNaN(parsedNewVal)) {
                setValidationErrorDialog({
                    status: DataValidationRespType.CellError,
                    title: 'Updating Cell Failed',
                    errorMsg: `"${params.newValue}" is not of valid value for ${columnName} (reason: We expected a floating number)`,
                });
                return false;
            }
        }

        let dryRun = creatingNewRow;
        let rowData = tableData[row];
        let primaryKeyValues: any = {};
        tableConfig.columns.forEach((columnConfig) => {
            if (!columnConfig.is_primary) return;
            primaryKeyValues[columnConfig.name] = rowData[columnConfig.name];
        });

        authPutFetcher<ValidateColumnResp>({
            description: `Updating Data in ${columnName} (${dryRun ? 'Dry Run' : ''})`,
            baseUrl: config.backendUrl,
            params: {
                dry_run: dryRun,
            },
            url: `/db/${tableConfig.db_id}/${tableConfig.name}/data/${columnConfig.name}`,
            data: {column_id: columnConfig.id, new_value: parsedNewVal, primary_key_values: primaryKeyValues},
            siteAlertOnError: {dispatch: dispatch},
            onSuccess: (data) => {
                if (data.success) {
                    let tmpData = [...tableData];
                    tmpData[row][params.colDef.field!] = parsedNewVal;
                    setTableDataClient(tmpData);
                    return;
                }
                setValidationErrorDialog({
                    status: DataValidationRespType.CellError,
                    title: 'Updating Cell Failed',
                    errorMsg: `"${params.newValue}" is not of valid value for ${columnName} (reason: ${data.failed_msg})`,
                });
            },
        });
        return false;
    };

    const onGridReady = (event: GridReadyEvent) => {
        setGridApi(event.api);
        event.api.setTabToNextCell(tabToNextCell);
    };

    const submitNewRow = () => {
        authPostFetcher<ValidateRowResp>({
            description: `Create New Row`,
            baseUrl: config.backendUrl,
            url: `/db/${tableConfig.db_id}/${tableConfig.name}/data`,
            data: {new_row: tableData[0], table_id: tableConfig.id},
            onSuccess: (data) => {
                if (!data.success) {
                    let errMsg = 'New row creation failed';
                    if (data.failed_column_name !== null) {
                        errMsg += ` because value of ${data.failed_column_name} is invalid`;
                    }
                    setValidationErrorDialog({
                        status: DataValidationRespType.RowError,
                        title: 'Creating New Row Failed',
                        errorMsg: `${errMsg} (reason: ${data.failed_msg})`,
                    });
                    return;
                }
                setCreatingNewRow(false);
                gridApi!.stopEditing();
                dispatch(siteSuccessOpenAction({message: 'New Row Added'}));
            },
        });
    };

    const addNewRow = () => {
        let newRow: any = {};
        let firstColumnName = '';
        tableConfig.columns.forEach((columnConfig) => {
            if (firstColumnName === '') firstColumnName = columnConfig.name;
            newRow[columnConfig.name] = null;
        });
        setTableDataClient([newRow, ...tableData]);
        setCreatingNewRow(true);
        setTimeout(() => {
            // Need some delay in order for the new row to be added in the state
            // TODO: We can use useEffect to get a callback when the state changes this would avoid the random timeout
            //  https://codersera.com/blog/how-to-use-callback-with-setstate-in-react/
            startEditingRow(0, firstColumnName);
        }, 200);
    };

    const startEditingRow = (rowIdx: number, colName: string) => {
        gridApi!.setFocusedCell(rowIdx, colName);
        gridApi!.startEditingCell({
            rowIndex: rowIdx,
            colKey: colName,
        });
    };

    const deleteRow = (rowIdx: number): void => {
        if (creatingNewRow) {
            let tmpData = tableData.filter((_, idx) => idx !== 0);
            setTableDataClient(tmpData);
            gridApi!.stopEditing();
            setCreatingNewRow(false);
            return;
        }
        let rowData = tableData[rowIdx];
        let primaryKey: any = {};
        tableConfig.columns.forEach((columnConfig) => {
            if (!columnConfig.is_primary) return;
            primaryKey[columnConfig.name] = rowData[columnConfig.name];
        });
        authDeleteFetcher<ValidateRowResp>({
            description: `Delete Row`,
            baseUrl: config.backendUrl,
            url: `/db/${tableConfig.db_id}/${tableConfig.name}/data`,
            data: {row_primary_id: primaryKey},
            onSuccess: (data) => {
                if (!data.success) {
                    let errMsg = 'Deleting row failed';
                    if (data.failed_column_name !== null) {
                        errMsg += ` because value of ${data.failed_column_name} is invalid`;
                    }
                    setValidationErrorDialog({
                        status: DataValidationRespType.RowError,
                        title: 'Deleting Row Failed',
                        errorMsg: `${errMsg} (reason: ${data.failed_msg})`,
                    });
                    return;
                }
                let tmpData = tableData.filter((_, idx) => idx !== rowIdx);
                setTableDataClient(tmpData);
                gridApi!.stopEditing();
                dispatch(siteSuccessOpenAction({message: 'Row Deleted'}));
            },
        });
    };

    return (
        <React.Fragment>
            <div style={{height: DatabaseTitleHeaderHeight}}>
                <Grid container style={{paddingTop: '10px', paddingLeft: '10px'}}>
                    <Grid item xs={2} style={{backgroundColor: 'clear'}}>
                        <h2 style={{margin: 0}}>{dbName}</h2>
                    </Grid>
                    <Grid item xs={7} style={{backgroundColor: 'clear'}}/>
                    <Grid item xs={3} style={{backgroundColor: 'clear', textAlign: 'right', paddingRight: '20px'}}>
                        {creatingNewRow && <Button
                            style={{marginRight: '5px'}}
                            variant={'outlined'}
                            startIcon={<DeleteIcon/>}
                            onClick={() => {
                                deleteRow(0)
                            }}>
                            Delete New Row
                        </Button>}
                        <Button
                            variant={creatingNewRow ? 'contained' : 'outlined'}
                            onClick={() => {
                                if (creatingNewRow) {
                                    submitNewRow();
                                    return;
                                }
                                addNewRow();
                            }}>
                            {creatingNewRow ? 'Submit New Row' : 'New Row'}
                        </Button>
                    </Grid>
                    {/*<Grid item xs={2} style={{backgroundColor: 'yellow'}}>*/}
                    {/*    {creatingNewRow && <Button*/}
                    {/*        variant={'outlined'}*/}
                    {/*        startIcon={<DeleteIcon/>}*/}
                    {/*        onClick={() => {*/}
                    {/*            if (creatingNewRow) {*/}
                    {/*                submitNewRow();*/}
                    {/*                return;*/}
                    {/*            }*/}
                    {/*            addNewRow();*/}
                    {/*        }}>*/}
                    {/*        {creatingNewRow ? 'Submit New Row' : 'New Row'}*/}
                    {/*    </Button>}*/}
                    {/*</Grid>*/}
                </Grid>
            </div>
            <UGrid
                validationErrorDialogState={validationErrorDialog}
                handleCloseValidationErrorDialog={handleCloseValidationErrorDialog}
                creatingNewRow={creatingNewRow}
                tableConfig={tableConfig}
                tableData={tableData}
                deleteRow={deleteRow}
                valueSetter={valueSetter}
                setTableData={setTableDataClient}
                onCellValueChanged={onCellValueChangedEvent}
                onGridReady={onGridReady}
            />
        </React.Fragment>
    );
};
