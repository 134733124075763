import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../store';
import {
    siteErrorCloseAction,
    siteSuccessCloseAction,
    SiteAlertsState,
    SiteAlertState,
    siteCloseActionFuncType,
} from '../store/siteAlerts';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

const SiteAlertsBars = () => {
    const siteAlertState = useSelector<SiteAlertsState>((state) => state.siteAlerts);
    return (
        <div>
            <SiteAlertBar siteAlertState={siteAlertState.errorAlert} siteAlertCloseAction={siteErrorCloseAction} />
            <SiteAlertBar siteAlertState={siteAlertState.successAlert} siteAlertCloseAction={siteSuccessCloseAction} />
        </div>
    );
};

const SiteAlertBar = ({
    siteAlertState,
    siteAlertCloseAction,
}: {
    siteAlertState: SiteAlertState | null;
    siteAlertCloseAction: siteCloseActionFuncType;
}) => {
    const dispatch = useDispatch();
    const closeAction = () => dispatch(siteAlertCloseAction());
    return (
        <Snackbar
            open={siteAlertState !== null}
            autoHideDuration={siteAlertState?.autoHideDuration}
            onClose={closeAction}
            key={siteAlertState?.message}
            anchorOrigin={siteAlertState?.anchorOrigin}>
            <Alert onClose={closeAction} severity={siteAlertState?.severity}>
                {siteAlertState?.message}
            </Alert>
        </Snackbar>
    );
};

export default SiteAlertsBars;
