import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip } from '@mui/material';
import { RDbResp, CUDDbDialogType } from './types';
import { useState } from 'react';
import { authDeleteFetcher, authPostFetcher } from '../../util/fetcher';
import config from '../../config';
import { useDispatch } from 'react-redux';
import { FetchState, initState, isLoading } from '../../swissTech/fetcher';
import { ButtonSpinner } from '../../swissTech/components/ButtonSpinner';

type CUDDbDialogProps = {
    openCUDDialog: CUDDbDialogType;
    handleCancel: () => void;
    handleCreate: (valDb: RDbResp) => void;
    handleUpdate: () => void;
    handleDelete: (valDb: RDbResp) => void;
};

export const CUDDbDialog = ({
    openCUDDialog,
    handleCancel,
    handleCreate,
    handleUpdate,
    handleDelete,
}: CUDDbDialogProps) => {
    const dispatch = useDispatch();
    const [dbName, setDbName] = useState<string | undefined>(openCUDDialog.db?.name);
    let isCreate = openCUDDialog.db === undefined;

    const [cDb, setCDb] = useState<FetchState<RDbResp>>(initState());
    const [dDb, setDDb] = useState<FetchState<RDbResp>>(initState());

    const onCreate = () => {
        authPostFetcher<RDbResp>({
            description: 'Create Database',
            baseUrl: config.backendUrl,
            url: '/db',
            data: { name: dbName },
            withState: { fetchState: cDb, setFetchState: setCDb },
            siteAlertOnError: { dispatch: dispatch },
            siteAlertOnSuccess: { dispatch: dispatch, msg: 'Database Created' },
            onSuccess: (data) => {
                handleCreate(data);
            },
        });
    };

    const onDelete = () => {
        authDeleteFetcher<RDbResp>({
            description: 'Delete Database',
            baseUrl: config.backendUrl,
            url: `/db/${openCUDDialog.db!.id}`,
            withState: { fetchState: dDb, setFetchState: setDDb },
            siteAlertOnError: { dispatch: dispatch },
            siteAlertOnSuccess: { dispatch: dispatch, msg: 'Database Deleted' },
            onSuccess: (data) => {
                handleDelete(data);
            },
        });
    };

    // For now you can not update a database as the only thing you can update is the name, and that should not be editable
    let mainActionDisabled = isCreate ? dbName === undefined : true;
    let deleteActionDisabled = isCreate;
    let userInteractionDisabled = isLoading(cDb.status) || isLoading(dDb.status);
    let buttonAction = isCreate ? onCreate : handleUpdate;
    let dbNameTooltipTitle = isCreate ? '' : 'Name cannot be changed after it has been created';
    let dbNameHelperText = isCreate ? 'Cannot be changed once created' : undefined;

    const handleCancelHelper = () => {
        if (userInteractionDisabled) return;
        handleCancel();
    };

    return (
        <Dialog fullWidth open={true} onClose={handleCancelHelper}>
            <DialogTitle>{isCreate ? 'Create' : 'Edit'} Database</DialogTitle>
            <DialogContent>
                <Tooltip title={dbNameTooltipTitle} placement={'top'}>
                    <TextField
                        autoFocus
                        disabled={!isCreate}
                        required
                        id="name"
                        label="Database name"
                        helperText={dbNameHelperText}
                        type="text"
                        fullWidth
                        value={dbName || ''}
                        onChange={(event) => {
                            let val: string | undefined = event.target.value;
                            if (val === '') val = undefined;
                            setDbName(val);
                        }}
                    />
                </Tooltip>
            </DialogContent>
            <DialogActions>
                {!isCreate && (
                    <ButtonSpinner
                        onClick={onDelete}
                        disabled={deleteActionDisabled || userInteractionDisabled}
                        loading={isLoading(dDb.status)}
                        buttonProps={{ color: 'error', style: { marginRight: 'auto' } }}>
                        Delete
                    </ButtonSpinner>
                )}
                <ButtonSpinner
                    onClick={handleCancelHelper}
                    disabled={userInteractionDisabled}
                    loading={false}
                    buttonProps={{ variant: 'text' }}>
                    Cancel
                </ButtonSpinner>
                <ButtonSpinner
                    onClick={buttonAction}
                    disabled={mainActionDisabled || userInteractionDisabled}
                    loading={isLoading(cDb.status)}>
                    {isCreate ? 'Create' : 'Update'}
                </ButtonSpinner>
            </DialogActions>
        </Dialog>
    );
};
